import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AdminRoleDetail]。
 */
export const AdminRoleDetailRouteConfig: RouteConfig = {
  path: "/AdminRoleDetail",
  name: "AdminRoleDetail",
  component: () => import("@@/views/adminRoleDetail/AdminRoleDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
