import { MenuItem } from "@/models/topMenu/MenuItem"

/**
 * メニューデータを定義します。
 */
export const opluxmenu: Array<MenuItem> = [
  {
    menuId: "companyList",
    description: "加盟店情報管理",
    path: "/CompanyList",
    nameId: "labels.menuLabels.companyList",
    icon: "mdi-home",
    screenId: "CompanyList",
    disabled: true,
  },
  {
    menuId: "ruleStudio",
    description: "ルールスタジオ",
    nameId: "labels.menuLabels.ruleStudio",
    icon: "",
    screenId: "",
    disabled: true,
    children: [
      {
        menuId: "ruleMasterList",
        description: "ルールマスター一覧",
        path: "/RuleMasterList",
        nameId: "labels.menuLabels.ruleMasterList",
        icon: "",
        screenId: "RuleMasterList",
        disabled: true,
      },
      {
        menuId: "rulePackageList",
        description: "ルールパッケージ一覧",
        path: "/rule-packages",
        nameId: "labels.menuLabels.rulePackageList",
        icon: "",
        screenId: "RulePackageList",
        disabled: true,
      },
      {
        menuId: "ruleReleaseList",
        description: "リリース情報一覧",
        path: "/release-info",
        nameId: "labels.menuLabels.ruleReleaseList",
        icon: "",
        screenId: "ReleaseList",
        disabled: true,
      },
      {
        menuId: "ruleMasterSharingRulePartsEdit",
        description: "共有ルールパーツ定義",
        path: "/RuleMasterSharingRulePartsEdit",
        nameId: "labels.menuLabels.ruleMasterSharingRulePartsEdit",
        icon: "",
        screenId: "RuleMasterSharingRulePartsEdit",
        disabled: true,
      },
      {
        menuId: "ruleMasterRelatedEventExtractionPartsEdit",
        description: "関連イベント抽出パーツ定義",
        path: "/RuleMasterRelatedEventExtractionPartsEdit",
        nameId: "labels.menuLabels.ruleMasterRelatedEventExtractionPartsEdit",
        icon: "",
        screenId: "RuleMasterRelatedEventExtractionPartsEdit",
        disabled: true,
      },
    ],
  },
  {
    menuId: "eventList",
    description: "イベント一覧",
    path: "/EventList",
    nameId: "labels.menuLabels.eventList",
    icon: "",
    screenId: "EventList",
    disabled: true,
  },
  {
    menuId: "keywordList",
    description: "キーワード一覧",
    path: "/KeywordList",
    nameId: "labels.menuLabels.keywordList",
    icon: "",
    screenId: "KeywordList",
    disabled: true,
  },
  {
    menuId: "admin",
    description: "かっこユーザー管理",
    nameId: "labels.menuLabels.admin",
    icon: "",
    screenId: "",
    disabled: true,
    children: [
      {
        menuId: "adminList",
        description: "かっこユーザー一覧",
        path: "/AdminList",
        nameId: "labels.menuLabels.adminList",
        icon: "",
        screenId: "AdminList",
        disabled: true,
      },
      {
        menuId: "adminRoleList",
        description: "かっこロール一覧",
        path: "/AdminRoleList",
        nameId: "labels.menuLabels.adminRoleList",
        icon: "",
        screenId: "AdminRoleList",
        disabled: true,
      },
    ],
  },
]
