import { VNode } from "vue"
import { Vue } from "vue-property-decorator"

import TransitEntryPoint from "@/components/eventDispatcher/transitEntryPoint/TransitEntryPoint.vue"

import App from "@@/App.vue"
import Communicator from "@@/components/eventDispatcher/communicator/Communicator.vue"

/**
 * コンポーネント関連ユーティリティ
 */
export class ComponentUtils {
  /**
   * ここで components/common 以下に定義されたコンポーネントをすべて読み込む。
   * @param createElement
   */
  static loadDefaults(createElement: Vue.CreateElement): VNode[] {
    const ret = []
    /*
     * 共通ユーティリティ的なコンポーネント（画面は必要なく、イベントを
     * 受け取って処理を行う様なもの）を登録する。
     */
    ret.push(createElement(Communicator))
    ret.push(createElement(TransitEntryPoint))
    ret.push(createElement(App)) // アプリ本体。恐らくこれが最後が良い。
    return ret
  }
}
