import { RequestHeader } from "@/oplux/v3/api/common/valueobject/RequestHeader"
import { RuleStudioProxyTelegram } from "@/oplux/v3/api/common/valueobject/RuleStudioProxyTelegram"

/**
 * ルールスタジオ用のリクエストです。
 */
export class RuleStudioProxyRequest {
  /**
   * 通信に関するメタ情報
   */
  info: RequestHeader = new RequestHeader()

  /**
   * RuleStudioProxyでの中継用の情報
   */
  telegram: RuleStudioProxyTelegram = new RuleStudioProxyTelegram()
}
