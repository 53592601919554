import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminDetailJa extends AbstractLocaleMessageObject {
  readonly title: string = "かっこユーザー情報詳細"

  readonly userId: string = "ユーザーID"

  readonly userName: string = "ユーザー名"

  readonly roleName: string = "ロール"

  readonly email: string = "メールアドレス"

  readonly passwordLock: string = "アカウントの状態"

  readonly passwordLockTrue: string = "ロック中"

  readonly passwordLockFalse: string = " - "

  readonly buttonEdit: string = "編集"

  readonly buttonCancel: string = "かっこユーザー情報一覧へ戻る"
}
