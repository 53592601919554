import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class LoginInputJa extends AbstractLocaleMessageObject {
  readonly inputLabelCompanyId: string = "加盟店ID"

  readonly inputTextBoxCompanyIdPlaceholder: string = "英数記号で入力してください"

  readonly inputLabelMailAddress: string = "メールアドレス"

  readonly inputTextBoxMailAddressPlaceholder: string = "メールアドレスを入力してください"

  readonly inputLabelPassword: string = "パスワード"

  readonly inputTextBoxPasswordPlaceholder: string = "英数記号で入力してください"

  readonly operationButtonForgotPassword: string = "パスワードを忘れた方はこちら"

  readonly operationButtonLogin: string = "ログイン"

  readonly confirmDialogButtonOk: string = "OK"

  readonly error906: string = "メールアドレス・パスワードに誤りがあるか、登録されていません。"
}
