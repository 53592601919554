import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AuthoriModelDetail]。
 */
export const AuthoriModelDetailRouteConfig: RouteConfig = {
  path: "/AuthoriModelDetail",
  name: "AuthoriModelDetail",
  component: () => import("@@/views/authoriModel/AuthoriModelDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
