import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [RuleMasterList]。
 */
export const RuleMasterListRouteConfig: RouteConfig = {
  path: "/RuleMasterList",
  name: "RuleMasterList",
  component: () => import("@@/views/ruleMasterList/RuleMasterList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
