import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AuthoriModelEdit]。
 */
export const AuthoriModelEditRouteConfig: RouteConfig = {
  path: "/AuthoriModelEdit",
  name: "AuthoriModelEdit",
  component: () => import("@@/views/authoriModel/AuthoriModelEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
