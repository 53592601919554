import { RouteConfig } from "vue-router"

export const ReleaseRulePackageDetailRouteConfig: RouteConfig = {
  path: "/release-info/:releaseId/rule-packages/:rulePackageId",
  name: "ReleaseRulePackageDetail",
  component: () => import("@@/views/releaseRulePackageDetail/ReleaseRulePackageDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
