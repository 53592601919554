import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [LabelList]。
 */
export const LabelListRouteConfig: RouteConfig = {
  path: "/LabelList",
  name: "LabelList",
  component: () => import("@@/views/labelList/LabelList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
