import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class MenuLabelsJa extends AbstractLocaleMessageObject {
  readonly companyList: string = "加盟店情報一覧"

  readonly ruleStudio: string = "ルールスタジオ"

  readonly ruleMasterList: string = "ルールマスター"

  readonly rulePackageList: string = "ルールパッケージ"

  readonly ruleReleaseList: string = "リリース情報"

  readonly eventList: string = "イベント一覧"

  readonly keywordList: string = "キーワード情報一覧"

  readonly ruleMasterSharingRulePartsEdit: string = "共有ルールパーツ"

  readonly ruleMasterRelatedEventExtractionPartsEdit: string = "関連イベント抽出パーツ"

  readonly ruleMasterTable: string = "テーブルカラム定義"

  readonly admin: string = "かっこユーザー管理"

  readonly adminList: string = "かっこユーザー情報一覧"

  readonly adminRoleList: string = "かっこロール情報一覧"

  readonly login: string = "ログイン"

  readonly logout: string = "ログアウト"
}
