import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AuthoriModelList]。
 */
export const AuthoriModelListRouteConfig: RouteConfig = {
  path: "/AuthoriModelList",
  name: "AuthoriModelList",
  component: () => import("@@/views/authoriModel/AuthoriModelList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
