import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [LabelAddEdit]。
 */
export const LabelAddEditRouteConfig: RouteConfig = {
  path: "/LabelAddEdit",
  name: "LabelAddEdit",
  component: () => import("@@/views/labelAddEdit/LabelAddEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
