import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RulePackageListJa extends AbstractLocaleMessageObject {
  readonly rulePackageDescription: string = "説明"

  readonly cancelBtn: string = "キャンセル"

  readonly normal: string = "通常"

  readonly simulation: string = "シミュレーション"

  readonly abTest: string = "A/Bテスト"

  readonly inputDescriptionPH: string = "説明を入力してください"

  readonly rulePackageCopyConfirm: string = "ルールパッケージ複写確認"

  readonly expirationDateStart: string = "開始日時"

  readonly expirationDateEnd: string = "終了日時"

  readonly datePH: string = "日付"

  readonly hourPH: string = "時刻"

  readonly minutePH: string = "分"

  readonly datePHEnd: string = "日付"

  readonly hourPHEnd: string = "時刻"

  readonly minutePHEnd: string = "分"
}
