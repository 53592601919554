import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class KeywordListJa extends AbstractLocaleMessageObject {
  readonly title: string = "キーワード情報一覧"

  readonly companyId: string = "加盟店ID"

  readonly companyIdPlaceholder: string = "{0}文字以内"

  readonly category: string = "カテゴリ"

  readonly categoryPlaceholder: string = "{0}文字以内"

  readonly keyword: string = "キーワード"

  readonly keywordPlaceholder: string = "空白区切り 各{0}文字以内"

  readonly buttonSearch: string = "検索"

  readonly buttonDelete: string = "削除"

  readonly buttonCSVOutput: string = "CSV出力"

  readonly buttonAddKeyword: string = "キーワード登録"

  readonly dialogDeleteTitle: string = "削除 確認"

  readonly tableName: string = "キーワード情報"

  readonly infoCompanyId: string = "加盟店ID"

  readonly infoUpdatedAt: string = "更新日時"

  readonly infoCategory: string = "カテゴリ"

  readonly infoKeyword: string = "キーワード"

  readonly infoUpdatedUser: string = "更新ユーザー"

  readonly noData: string = "データがありません"
}
