import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorRoleListJa extends AbstractLocaleMessageObject {
  readonly companyId: string = "加盟店ID"

  readonly addButton: string = "加盟店ロール情報登録"

  readonly backButton: string = "加盟店情報詳細画面に戻る"

  readonly roleId: string = "加盟店ロールID"

  readonly roleName: string = "加盟店ロール名"

  readonly updatedAt: string = "更新日時"

  readonly detailButton: string = "詳細"
}
