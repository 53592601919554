import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [RuleMasterAdd]。
 */
export const RuleMasterAddRouteConfig: RouteConfig = {
  path: "/rules/new",
  name: "RuleMasterAdd",
  component: () => import("@@/views/ruleMasterAdd/RuleMasterAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
