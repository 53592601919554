import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class KeywordAddJa extends AbstractLocaleMessageObject {
  readonly title: string = "キーワード情報一覧"

  readonly companyId: string = "加盟店ID"

  readonly companyIdPlaceholder: string = "{0}文字以内"

  readonly category: string = "カテゴリ"

  readonly categoryPlaceholder: string = "{0}文字以内"

  readonly keyword: string = "キーワード"

  readonly keywordPlaceholder: string = "改行区切り 各{0}文字以内"

  readonly buttonAddKeyword: string = "登録"

  readonly titleKeywordAddSearch: string = "CSVファイルから追加"

  readonly buttonUploadCSV: string = "ファイルを選択"

  readonly buttonCSVAdd: string = "登録"

  readonly titleKeywordAddSearchResult: string = "登録履歴"

  readonly companyIdForHistorySearch: string = "加盟店ID"

  readonly createdAtForHistorySearch: string = "登録日付"

  readonly createdAtFrom: string = "日付"

  readonly createdAtTo: string = "日付"

  readonly buttonHistorySearch: string = "検索"

  readonly searchHistoryCompanyId: string = "加盟店ID"

  readonly searchHistoryCompanyIdPlaceholder: string = "{0}文字以内"

  readonly historyCompanyId: string = "加盟店ID"

  readonly historyCreatedAt: string = "登録日時"

  readonly historyUploadType: string = "登録種別"

  readonly historyCategory: string = "カテゴリ"

  readonly historyRegistCount: string = "件数"

  readonly historyRegistUserName: string = "登録ユーザー"

  readonly historyStatus: string = "ステータス"

  readonly historyInputPath: string = "アップロードファイル"

  readonly historyOutputPath: string = "結果ファイル"

  readonly buttonDownloadFileUploaded: string = "ダウンロード"

  readonly buttonDownloadFileResult: string = "ダウンロード"

  readonly dialogAddKeywordTitle: string = "キーワード登録"

  readonly tableName: string = "キーワード情報"

  readonly dialogAddCSVTitle: string = "ファイルアップロード登録"

  readonly noData: string = "データがありません"

  readonly toKeywordList: string = "一覧に戻る"

  readonly illegalExtensionMessage: string = "ファイルの拡張子が不正です"

  readonly csvRegistrationRequiredMessage: string = "必須項目です"
}
