import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class ReleaseRulePackageDetailJa extends AbstractLocaleMessageObject {
  readonly companyId: string = "加盟店ID"

  readonly releaseId: string = "リリースID"

  readonly authoriModelId: string = "審査モデルID"

  readonly createdAt: string = "作成日時"

  readonly createdBy: string = "作成ユーザー"

  readonly rulePackageId: string = "ルールパッケージID"

  readonly authKind: string = "審査種別"

  readonly expirationDate: string = "有効期間"

  readonly backBtn: string = "戻る"

  readonly description: string = "説明"
}
