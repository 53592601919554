import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AuthoriModelDetailJa extends AbstractLocaleMessageObject {
  readonly companyIdLabel: string = "加盟店ID"

  readonly publicAuthoriModelIdLabel: string = "タグ連携用審査モデルID"

  readonly publicAuthoriModelIdPlaceholder: string = "登録時に自動で採番されます"

  readonly authoriModelIdLabel: string = "審査モデルID(必須)"

  readonly authoriModelIdPlaceholder: string = "英数字ハイフンまたはアンダーバーのみ使用可能。20文字まで"

  readonly descriptionLabel: string = "審査モデル説明(必須)"

  readonly descriptionPlaceholder: string = "審査モデルの説明を入力できます。"

  readonly forcedTerminationLabel: string = "強制終了(必須)"

  readonly forcedTerminationTimeout: string = "タイムアウト{param}秒"

  readonly forcedTerminationTimeoutLabel: string = "タイムアウト"

  readonly forcedTerminationTimeoutUnit: string = "秒"

  readonly forcedTerminationTimeoutPlaceholder: string = "デフォルト60"

  readonly forcedTerminationBasedFlg: string = "申請日時を強制終了の基準とする"

  readonly forcedTerminationBasedFlgOn: string = "申請日時を強制終了の基準とする（有効）"

  readonly forcedTerminationBasedFlgOff: string = "申請日時を強制終了の基準とする（無効）"

  readonly invalidLabel: string = "状態"

  readonly invalidValue: string = "この審査モデルは無効です。"

  readonly validValue: string = "この審査モデルは有効です。"

  readonly invalidValueCheckbox: string = "この審査モデルを無効にする"

  readonly telCanalizationLabel: string = "電話疎通履歴選択(必須)"

  readonly telCanalizationType2: string = "電話番号疎通情報クローバー"

  readonly telCanalizationType1: string = "電話番号疎通情報ジンテック"

  readonly telCanalizationType0: string = "使用しない"

  readonly ipAddrInfoLabel: string = "IPアドレスAPIデータ(必須)"

  readonly ipAddrInfoValueOn: string = "有効"

  readonly ipAddrInfoValueOff: string = "無効"

  readonly geocodingApiFlagLabel: string = "ジオコーディングAPIフラグ(必須)"

  readonly geocodingApiFlagValueOn: string = "有効"

  readonly geocodingApiFlagValueOff: string = "無効"

  readonly manualAuthoriReasonLabel: string = "目視審査結果理由"

  readonly manualAuthoriReasonOkLabel: string = "OK"

  readonly manualAuthoriReasonOkSelect: string = "複数選択あり"

  readonly manualAuthoriReasonNgLabel: string = "NG"

  readonly manualAuthoriReasonNgSelect: string = "複数選択あり"

  readonly manualAuthoriReasonHoldLabel: string = "HOLD"

  readonly manualAuthoriReasonHoldSelect: string = "複数選択あり"

  readonly manualAuthoriReasonSelectValueOn: string = "複数選択あり"

  readonly manualAuthoriReasonSelectValueOff: string = "複数選択なし"

  readonly manualAuthoriReasonHintLabel: string = "※複数登録する場合には、改行で区切って入力してください。"

  readonly authoriModelConfLabel: string = "決済金額合計対象外項目設定"

  readonly authoriModelConfSettleStatus: string = "決済ステータス"

  readonly authoriModelConfAuto: string = "自動審査"

  readonly authoriModelConfManual: string = "目視審査結果"

  readonly authoriModelConfHintLabel: string =
    "※目視審査で、目視審査モデルの決済金額の合計に加算したくないデータの設定をしてください。"

  readonly notCoveredManualLabel: string = "目視審査対象外設定"

  readonly notCoveredManualVCol02Prefix: string = "自動審査{name}"

  readonly notCoveredManualHintLabel: string = "※目視審査対象外とする自動審査結果を選択してください。"

  readonly notifyLabel: string = "通知情報"

  readonly notifyNamePrefix: string = "{name} Notify"

  readonly overseasFlagLabel: string = "海外モデル"

  readonly overseasFlagValueOn: string = "YES"

  readonly overseasFlagValueOff: string = "NO"

  readonly omotionCooperationLabel: string = "O-motion連携"

  readonly omotionCooperationFlagLabel: string = "O-motion連携の有無"

  readonly omotionCooperationFlagValueOn: string = "YES"

  readonly omotionCooperationFlagValueOff: string = "NO"

  readonly omotionInfoLabel: string = "O-motion認証情報"

  readonly omotionInfoSignature: string = "加盟店署名"

  readonly omotionInfoSignaturePlaceholder: string = "O-motionで指定された加盟店署名を入力してください"

  readonly omotionInfoUserId: string = "加盟店ユーザーID"

  readonly relatedEventLabel: string = "関連イベント設定"

  readonly relatedEventId: string = "関連イベント抽出パーツID"

  readonly relatedEventIdPlaceholder: string = "選択してください"

  readonly relatedEventIdButton: string = "参照"

  readonly relatedEventTimeout: string = "タイムアウト値"

  readonly relatedEventTimeoutUnit: string = "秒"

  readonly relatedEventTimeoutPlaceholder: string = "推奨60"

  readonly relatedEventResponseFlg: string = "レスポンス"

  readonly relatedEventResponseFlgValueOn: string = "有効"

  readonly relatedEventResponseFlgValueOff: string = "無効"

  readonly addressJudgmentFlagLabel: string = "住所判定"

  readonly addressJudgmentFlagValueOn: string = "有効"

  readonly addressJudgmentFlagValueOff: string = "無効"

  readonly updatedAtLabel: string = "最終更新日"

  readonly updatedByLabel: string = "最終更新者"

  readonly buttonEdit: string = "編集"

  readonly buttonBack: string = "戻る"

  readonly notifyValueOk: string = "OK Notify"

  readonly notifyValueNg: string = "NG Notify"

  readonly notifyValueHold: string = "HOLD Notify"

  readonly notifyValueReview: string = "REVIEW Notify"

  readonly notifyValueError: string = "ERROR Notify"

  readonly labelYes: string = "YES_"

  readonly labelNo: string = "NO_"

  readonly labelValid: string = "有効_"

  readonly labelInvalid: string = "無効_"

  readonly settleStatus00: string = "請求前(00)"

  readonly settleStatus10: string = "請求中(10)"

  readonly settleStatus20: string = "支払済(20)"

  readonly settleStatus30: string = "期限超過(30)"

  readonly settleStatus40: string = "債権委託済(40)"

  readonly settleStatus50: string = "貸倒(50)"

  readonly settleStatus60: string = "チャージバック(60)"

  readonly settleStatus99: string = "キャンセル(99)"

  readonly settleStatus100: string = "送付後キャンセル(100)"

  readonly autoAuthoriResult10: string = "OK(10)"

  readonly autoAuthoriResult20: string = "NG(20)"

  readonly autoAuthoriResult30: string = "HOLD(30)"

  readonly autoAuthoriResult40: string = "REVIEW(40)"

  readonly manualAuthoriResult10: string = "OK(10)"

  readonly manualAuthoriResult20: string = "NG(20)"

  readonly manualAuthoriResult30: string = "HOLD(30)"
}
