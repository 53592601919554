import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RelatedEventExtractionPartsSelectDlgJa extends AbstractLocaleMessageObject {
  readonly relatedEventExtractionPartsSelectDlgLabel: string = "関連イベント抽出パーツ選択"

  readonly categoryLabel: string = "カテゴリリスト"

  readonly partsLabel: string = "条件式リスト"

  readonly okBtnLabel: string = "OK"

  readonly cancelBtnLabel: string = "キャンセル"

  readonly partsSeparateLabel: string = "："
}
