import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AuthoriModelListEn extends AbstractLocaleMessageObject {
  readonly title: string = "Judgement Model List"

  readonly infoLabelId: string = "Shop Id:"

  readonly infoLabelName: string = "Shop Name:"

  readonly searchDropDownStatus1: string = "valid"

  readonly searchDropDownStatus2: string = "invalid"

  readonly searchDropDownStatus3: string = "all"

  readonly searchTableHeaderModelId: string = "model-id"

  readonly searchTableHeaderDescription: string = "description"

  readonly searchTableHeaderDetail: string = "info"

  readonly searchTableHeaderCopy: string = "copy"

  readonly searchTableHeaderCreateAt: string = "created-date"

  readonly searchTableHeaderUpdateAt: string = "update-date"

  readonly searchTableHeaderUpdateUser: string = "update-user"

  readonly searchTableHeaderReleaseAt: string = "released-date"

  readonly searchTableHeaderStatus: string = "status"

  readonly searchTableHeaderPackage: string = "package"

  readonly operationButtonRegist: string = "ADD"

  readonly operationButtonBack: string = "BACK"
}
