import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CombinatJudgmentEdit]。
 */
export const CombinatJudgmentEditRouteConfig: RouteConfig = {
  path: "/rule-packages/:rulePackageId/edit",
  name: "CombinatJudgmentEdit",
  component: () => import("@@/views/combinatJudgmentEdit/CombinatJudgmentEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
