import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [PasswordChangeRequest]。
 */
export const PasswordChangeRequestRouteConfig: RouteConfig = {
  path: "/PasswordChangeRequest",
  name: "PasswordChangeRequest",
  component: () => import("@@/views/passwordChange/PasswordChangeRequest.vue"),
  meta: {
    reload: true,
    authRequired: false,
  },
}
