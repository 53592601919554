import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [RuleMasterDetail]。
 */
export const RuleMasterDetailRouteConfig: RouteConfig = {
  path: "/rules/:ruleId",
  name: "RuleMasterDetail",
  component: () => import("@@/views/ruleMasterDetail/RuleMasterDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
