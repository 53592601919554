import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AuthoriModelEditJa extends AbstractLocaleMessageObject {
  readonly buttonEdit: string = "更新"

  readonly buttonBack: string = "キャンセル"

  readonly confirmDialogButtonOk: string = "実行"

  readonly confirmDialogButtonCancel: string = "キャンセル"
}
