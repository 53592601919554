import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CompanyEditJa extends AbstractLocaleMessageObject {
  readonly companyId: string = "加盟店ID"

  readonly companyName: string = "加盟店名"

  readonly contractDiv: string = "契約区分"

  readonly isTrial: string = "トライアル加盟店"

  readonly trialOption: string = "トライアル審査の詳細設定"

  readonly isFormallyContracted: string = "本契約済み(トライアル期間終了後に本審査を受け付けます)"

  readonly trialEventLimitCount: string = "トライアル審査件数の上限"

  readonly trialEventCurrentCount: string = "トライアル審査件数の現在数: {trialEventCurrentCount} 件"

  readonly trialDurationDays: string = "トライアル審査期間（日数）"

  readonly trialEndDateTime: string = "トライアル審査終了日時"

  readonly businessType: string = "事業者形態"

  readonly zipcode: string = "郵便番号"

  readonly address: string = "住所"

  readonly tel: string = "電話番号"

  readonly ipaddressUi: string = "IPアドレス（画面用）"

  readonly ipaddressApi: string = "IPアドレス（API用）"

  readonly domain: string = "ドメイン"

  readonly publicCompanyId: string = "タグ連携用加盟店ID"

  readonly applicationId: string = "アプリケーションID"

  readonly secretAccessId: string = "シークレットアクセスキー"

  readonly globalNegativeFlag: string = "グローバルネガティブフラグ"

  readonly csvAuthoriDuplicateShopEventIdFlag: string = "CSV審査加盟店管理ID重複許可フラグ"

  readonly negativeCategoryIdSettlemen: string = "ネガティブ決済カテゴリ"

  readonly negativeCategoryOther: string = "ネガティブその他カテゴリ"

  readonly invalid: string = "無効フラグ"

  readonly memo: string = "メモ"

  readonly updateButton: string = "更新"

  readonly cancelButton: string = "キャンセル"

  readonly okButton: string = "OK"

  readonly info50Moji: string = "50文字以内で入力してください。"

  readonly infoHyphen: string = "ハイフンなしで入力してください。"

  readonly shouldInputTrialDurationDays: string = "トライアル審査期間（日数）を入力してください。"

  readonly endTrialAfterTrialDuration: string = "最初に審査が行われた {trialDurationDays} 日後"

  readonly infoCheckItem: string = "チェックされた項目:"

  readonly infoScreenInfo: string = "加盟店情報"
}
