import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class PasswordChangeRequestJa extends AbstractLocaleMessageObject {
  readonly inputLabelCompanyId: string = "加盟店ID"

  readonly inputTextBoxCompanyIdPlaceholder: string = "英数字50文字まで"

  readonly inputLabelMailAddress: string = "メールアドレス"

  readonly inputTextBoxMailAddressPlaceholder: string = "ログインで使用するメールアドレスを入力してください"

  readonly operationButtonSend: string = "メール送信"

  readonly operationHyperlinkBack: string = "ログイン画面へ戻る"
}
