import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterConstantDlgJa extends AbstractLocaleMessageObject {
  readonly constLabel: string = "定数設定"

  readonly dataLabel: string = "データ型"

  readonly stringLabel: string = "文字列"

  readonly numberLabel: string = "数値"

  readonly dateLabel: string = "日付"

  readonly dateTimeLabel: string = "日時"

  readonly item: string = "値"

  readonly itemPH: string = "値を入力してください。"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly label01: string = "データ型を選択してください"
}
