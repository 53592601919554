import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [RuleMasterSharingRulePartsEdit]。
 */
export const RuleMasterSharingRulePartsEditRouteConfig: RouteConfig = {
  path: "/RuleMasterSharingRulePartsEdit",
  name: "RuleMasterSharingRulePartsEdit",
  component: () => import("@@/views/ruleMasterSharingRulePartsEdit/RuleMasterSharingRulePartsEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
