import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AuthoriModelListJa extends AbstractLocaleMessageObject {
  readonly title: string = "審査モデル一覧"

  readonly infoLabelId: string = "加盟店ID"

  readonly searchTableHeaderModelId: string = "審査モデルID"

  readonly searchTableHeaderDescription: string = "説明"

  readonly searchTableHeaderDetail: string = "詳細"

  readonly searchTableHeaderCopy: string = "複写"

  readonly searchTableHeaderCreateAt: string = "作成日時"

  readonly searchTableHeaderUpdateAt: string = "更新日時"

  readonly searchTableHeaderUpdateUser: string = "更新ユーザー"

  readonly searchTableHeaderReleaseAt: string = "本番リリース日時"

  readonly searchTableHeaderPackage: string = "ルールパッケージ"

  readonly searchTableHeaderInvalid: string = "無効"

  readonly searchTableHeaderInvalidAt: string = "無効日時"

  readonly operationButtonRegist: string = "新規作成"

  readonly operationButtonBack: string = "加盟店情報詳細画面に戻る"

  readonly copyDialogHeadline: string = "審査モデル情報 複写確認"

  readonly copyDialogInfoLabel: string = "複写元情報"

  readonly copyDialogInputLabelCompnayId: string = "加盟店ID"

  readonly copyDialogInputLabelAuthoriId: string = "審査モデルID"

  readonly copyDialogInputLabelDescription: string = "説明"

  readonly copyDialogInputPlaceholderCompnayId: string = "加盟店IDを半角英数字で入力してください"

  readonly copyDialogInputPlaceholderAuthoriId: string = "審査モデルIDを半角英数字で入力してください"

  readonly copyDialogInputPlaceholderDescription: string = "審査モデルの説明を入力してください"

  readonly copyDialogButtonOk: string = "実行"

  readonly copyDialogButtonCancel: string = "キャンセル"

  readonly searchTableDropDownPageCount: string = "{count}件表示"

  readonly searchTableDropDownLabel: string = "表示件数"
}
