import { RouteConfig } from "vue-router"

export const RulePackagesRouteConfig: RouteConfig = {
  path: "/rule-packages",
  name: "RulePackageList", // 権限の関係上、改修前の画面のScreenIdで定義
  component: () => import("./RulePackages.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
