import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterRelatedEventExtractionPartsEditJa extends AbstractLocaleMessageObject {
  readonly relatedEventCategoryList: string = "カテゴリリスト"

  readonly relatedEventCategoryInfo: string = "カテゴリ情報"

  readonly relatedEventCategoryName: string = "カテゴリ名"

  readonly addBtn: string = "追加"

  readonly relatedEventExtractionPartsList: string = "関連イベント抽出パーツリスト"

  readonly copyBtn: string = "複写"

  readonly ruleInfoConditionAdd: string = "条件式グループ追加"

  readonly infoCategoryScreenInfo: string = "関連イベント抽出パーツカテゴリ情報"

  readonly infoScreenInfo: string = "関連イベント抽出パーツ情報"

  readonly titleDlg: string = "関連イベント抽出パーツ複写"

  readonly copyCategoryName: string = "複写先カテゴリ名"

  readonly ruleInfoConditionDescription: string = "条件式の説明"
}
