import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AdminRoleAdd]。
 */
export const AdminRoleAddRouteConfig: RouteConfig = {
  path: "/AdminRoleAdd",
  name: "AdminRoleAdd",
  component: () => import("@@/views/adminRoleAdd/AdminRoleAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
