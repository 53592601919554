import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class LabelAddEdit extends AbstractLocaleMessageObject {
  readonly title: string = "ラベル情報登録・編集"

  readonly companyId: string = "加盟店ID"

  readonly labelName: string = "ラベル名"

  readonly labelNamePlaceholder: string = "{0}文字以内"

  readonly buttonAdd: string = "登録"

  readonly buttonEdit: string = "更新"

  readonly buttonDelete: string = "削除"

  readonly buttonCancel: string = "キャンセル"

  readonly dialogAddTitle: string = "登録 確認"

  readonly dialogEditTitle: string = "更新 確認"

  readonly dialogDeleteTitle: string = "削除 確認"

  readonly dialogAddMessage: string = "登録します。 よろしいですか？"

  readonly dialogEditMessage: string = "更新します。 よろしいですか？"

  readonly tableName: string = "ラベル"

  readonly toastAddSuccess: string = "登録しました"

  readonly toastEditSuccess: string = "更新しました"
}
