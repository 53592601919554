import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [KeywordAdd]。
 */
export const KeywordAddRouteConfig: RouteConfig = {
  path: "/KeywordAdd",
  name: "KeywordAdd",
  component: () => import("@@/views/keywordAdd/KeywordAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
