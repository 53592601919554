import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminRoleDetailJa extends AbstractLocaleMessageObject {
  readonly roleId: string = "ロールID"

  readonly roleName: string = "ロール名"

  readonly functionGroup: string = "利用可能機能グループ"

  readonly editAddLabel: string = "編集・登録"

  readonly can: string = "できる"

  readonly canNot: string = "できない"

  readonly notProvideFunction: string = "機能を提供しない"

  readonly editButton: string = "編集"

  readonly backButton: string = "かっこユーザーロール情報一覧へ戻る"
}
