/**
 * IPアドレスV4のバリデーション
 *  許容する形式は、10進数オクテット/区切りでネットマスクビット数（CIDR形式）です。
 * @param value
 * @param params
 */
export function ipAddressV4Validator(value: any, params: any[] | Record<string, any>) {
  // スラッシュなしでも許容する場合
  // if (/^(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))((\.(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))){3})(\/(([0-9])|([12][0-9])|(3[0-2])))?$/.test(value)) {
  //   return true;
  // }
  // return false;
  // CIDR形式のみ許容する場合
  if (
    /^(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))((\.(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))){3})\/(([0-9])|([12][0-9])|(3[0-2]))$/.test(
      value
    )
  ) {
    return true
  }
  return false
}
