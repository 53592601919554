import { RouteConfig } from "vue-router"

export const RulePackageBulkEditDeleteConfig: RouteConfig = {
  path: "/rule-package-bulk-edit-delete",
  name: "RulePackageBulkEditDelete",
  component: () => import("@@/views/rulePackageBulkEditDelete/RulePackageBulkEditDelete.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
  props: true,
}
