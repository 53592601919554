import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CombinatJudgmentEditJa extends AbstractLocaleMessageObject {
  readonly companyId: string = "加盟店ID"

  readonly authoriModelId: string = "審査モデルID"

  readonly description: string = "説明"

  readonly createdAt: string = "作成日時"

  readonly updatedAt: string = "更新日時"

  readonly updatedBy: string = "更新ユーザー"

  readonly releasedAt: string = "本番リリース日時"

  readonly rulePackageId: string = "ルールパッケージID"

  readonly authKind: string = "審査種別"

  readonly expirationDate: string = "有効期間"

  readonly group: string = "グループ"

  readonly unitAmount: string = "ユニット数"

  readonly totalRuleAmount: string = "総ルール数"

  readonly threshold: string = "閾値"

  readonly ruleAmount: string = "ルール数"

  readonly ruleGroupJudgmentDefault: string = "ルールグループ判定既定値"

  readonly editBtn: string = "編集"

  readonly backBtn: string = "戻る"

  readonly updateBtn: string = "更新"

  readonly clipBoardBtn: string = "クリップボードから取り込み"

  readonly changeBtn: string = "変更"

  readonly addBtn: string = "追加"

  readonly deleteBtn: string = "削除"

  readonly unitEditBtn: string = "ユニット編集"

  readonly unitCopyBtn: string = "ユニット複写"

  readonly unitDeleteBtn: string = "ユニット削除"

  readonly unitCreateBtn: string = "ユニット作成"

  readonly priorityEditDialogTitle: string = "優先順位変更"

  readonly ruleGroupJudgmentDefaultEditDialogTitle: string = "ルールグループ判定既定値変更"

  readonly groupAddDialogTitle: string = "グループ追加"

  readonly unitAddEditDialogTitle: string = "ユニット追加/編集"

  readonly unitCopyDialogTitle: string = "ユニット複写"

  readonly groupNameLabel: string = "グループ名"

  readonly ruleGroupJudgmentLabel: string = "判定"

  readonly cancelBtn: string = "キャンセル"

  readonly selectingRules: string = "選択中のルール"

  readonly okBtn: string = "OK"

  readonly copyBtn: string = "複写"

  readonly expirationDateStart: string = "開始日時"

  readonly expirationDateEnd: string = "終了日時"

  readonly combinatJudgmentEditLabel: string = "ルールパッケージ"

  readonly datePH: string = "日付"

  readonly hourPH: string = "時刻"

  readonly minutePH: string = "分"

  readonly datePHEnd: string = "日付"

  readonly hourPHEnd: string = "時刻"

  readonly minutePHEnd: string = "分"

  readonly groupAddNameLabel: string = "グループ名"
}
