import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminListJa extends AbstractLocaleMessageObject {
  readonly userId: string = "ユーザーID"

  readonly userName: string = "ユーザー名"

  readonly roleName: string = "ロール名"

  readonly email: string = "メールアドレス"

  readonly createdAt: string = "作成日時"

  readonly buttonToAdminAdd: string = "登録"

  readonly noData: string = "データがありません"

  readonly dialogDeleteTitle: string = "削除確認"

  readonly tableName: string = "かっこユーザー"
}
