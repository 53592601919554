import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CommonTitleHeaderJa extends AbstractLocaleMessageObject {
  readonly loginInput: string = "ログイン"

  readonly passwordChange: string = "パスワード変更"

  readonly passwordChangeRequest: string = "パスワード変更依頼"

  readonly companyList: string = "加盟店情報一覧"

  readonly companyAdd: string = "加盟店情報登録"

  readonly companyDetail: string = "加盟店情報詳細"

  readonly companyEdit: string = "加盟店情報編集"

  readonly operatorList: string = "加盟店オペレータ情報一覧"

  readonly operatorAdd: string = "加盟店オペレータ情報登録"

  readonly operatorDetail: string = "加盟店オペレータ情報詳細"

  readonly operatorEdit: string = "加盟店オペレータ情報編集"

  readonly operatorRoleList: string = "加盟店ロール情報一覧"

  readonly operatorRoleAdd: string = "加盟店ロール情報登録"

  readonly operatorRoleDetail: string = "加盟店ロール情報詳細"

  readonly operatorRoleEdit: string = "加盟店ロール情報編集"

  readonly labelList: string = "ラベル情報一覧"

  readonly labelAddEdit: string = "ラベル情報登録・編集"

  readonly authoriModelList: string = "審査モデル情報一覧"

  readonly authoriModelAdd: string = "審査モデル基本情報登録"

  readonly authoriModelDetail: string = "審査モデル基本情報詳細"

  readonly authoriModelEdit: string = "審査モデル基本情報編集"

  readonly rulePackageList: string = "ルールパッケージ一覧"

  readonly combinatJudgmentDetail: string = "ルールパッケージ詳細"

  readonly combinatJudgmentAdd: string = "ルールパッケージ登録"

  readonly combinatJudgmentEdit: string = "ルールパッケージ編集"

  readonly rulePackageBulkEditAdd: string = "ルールパッケージ一括編集：ルール追加"

  readonly rulePackageBulkEditDelete: string = "ルールパッケージ一括編集：ルール削除"

  readonly releaseList: string = "リリース情報一覧"

  readonly releaseRulePackageDetail: string = "リリース情報ルールパッケージ詳細"

  readonly ruleMasterList: string = "ルールマスター一覧"

  readonly ruleMasterAdd: string = "ルール登録"

  readonly ruleMasterDetail: string = "ルール詳細"

  readonly ruleMasterEdit: string = "ルール編集"

  readonly authoriModelincludingRuleList: string = "所属審査モデル一覧"

  readonly eventList: string = "イベント一覧"

  readonly eventDetail: string = "イベント詳細"

  readonly keywordList: string = "キーワード情報一覧"

  readonly keywordAdd: string = "キーワード情報登録・履歴"

  readonly ruleMasterSharingRulePartsEdit: string = "共有ルールパーツ定義"

  readonly ruleMasterRelatedEventExtractionPartsEdit: string = "関連イベント抽出パーツ定義"

  readonly ruleMasterTable: string = "テーブルカラム定義"

  readonly ruleInfoCondition: string = "条件式グループ"

  readonly adminList: string = "かっこユーザー情報一覧"

  readonly adminAdd: string = "かっこユーザー情報登録"

  readonly adminDetail: string = "かっこユーザー情報詳細"

  readonly adminEdit: string = "かっこユーザー情報編集"

  readonly adminRoleList: string = "かっこユーザーロール情報一覧"

  readonly adminRoleAdd: string = "かっこユーザーロール情報登録"

  readonly adminRoleDetail: string = "かっこユーザーロール情報詳細"

  readonly adminRoleEdit: string = "かっこユーザーロール情報編集"

  readonly systemError: string = "システムエラー"

  readonly noPage: string = "システムエラー"
}
