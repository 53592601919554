import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class SharingRulePartsSelectDlgJa extends AbstractLocaleMessageObject {
  readonly sharingPartsLabel: string = "共有ルールパーツ選択"

  readonly categoryLabel: string = "カテゴリリスト"

  readonly partsLabel: string = "条件式リスト"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly sharingPartsMessage: string = "共有ルールパーツ"
}
