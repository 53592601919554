import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AdminDetail]。
 */
export const AdminDetailRouteConfig: RouteConfig = {
  path: "/AdminDetail",
  name: "AdminDetail",
  component: () => import("@@/views/adminDetail/AdminDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
