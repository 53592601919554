import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AdminAdd]。
 */
export const AdminAddRouteConfig: RouteConfig = {
  path: "/AdminAdd",
  name: "AdminAdd",
  component: () => import("@@/views/adminAdd/AdminAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
