import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [EventDetail]。
 */
export const EventDetailRouteConfig: RouteConfig = {
  path: "/EventDetail",
  name: "EventDetail",
  component: () => import("@@/views/eventDetail/EventDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
