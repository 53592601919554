import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminRoleListJa extends AbstractLocaleMessageObject {
  readonly addButton: string = "かっこユーザーロール情報登録"

  readonly backButton: string = "戻る"

  readonly roleId: string = "ロールID"

  readonly roleName: string = "ロール名"

  readonly updatedAt: string = "更新日時"

  readonly detailButton: string = "詳細"
}
