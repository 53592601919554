import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterDBColumnDlgJa extends AbstractLocaleMessageObject {
  readonly tableColumnLabel: string = "テーブルカラム選択"

  readonly tableNameLabel: string = "テーブル名"

  readonly searchBtn: string = "検索"

  readonly columnNameLabel: string = "カラム名"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly searchTablePH: string = "テーブル名を入力してください"

  readonly searchColumnPH: string = "カラム名を入力してください"

  readonly descriptionLabel: string = "説明"

  readonly noSelectedDescription: string = "選択されていません"
}
