import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class LabelListJa extends AbstractLocaleMessageObject {
  readonly title: string = "ラベル情報一覧"

  readonly companyId: string = "加盟店ID"

  readonly tableId: string = "ID"

  readonly tableLabelName: string = "ラベル名"

  readonly buttonEdit: string = "編集"

  readonly buttonAdd: string = "登録"

  readonly buttonBack: string = "加盟店情報詳細画面に戻る"

  readonly noTableData: string = "データがありません"
}
