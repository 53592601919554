import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminAddJa extends AbstractLocaleMessageObject {
  readonly title: string = "かっこユーザー情報登録"

  readonly userName: string = "ユーザー名"

  readonly userNamePlaceholder: string = "30文字以内で入力して下さい"

  readonly roleName: string = "ロール"

  readonly rolePlaceholder: string = "選択して下さい"

  readonly email: string = "メールアドレス"

  readonly emailPlaceholder: string = "半角英数字記号のみ使用できます"

  readonly buttonAdd: string = "登録"

  readonly buttonCancel: string = "キャンセル"

  readonly dialogTitle: string = "登録 確認"

  readonly tableName: string = "かっこユーザー"
}
