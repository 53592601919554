import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AuthoriModelAddJa extends AbstractLocaleMessageObject {
  readonly buttonAdd: string = "登録"

  readonly buttonBack: string = "キャンセル"

  readonly confirmDialogButtonOk: string = "実行"

  readonly confirmDialogButtonCancel: string = "キャンセル"
}
