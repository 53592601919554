import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CompanyAdd]。
 */
export const CompanyAddRouteConfig: RouteConfig = {
  path: "/CompanyAdd",
  name: "CompanyAdd",
  component: () => import("@@/views/companyAdd/CompanyAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
