import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { AuthoriModelListEn } from "./AuthoriModelListEn"
import { MenuLabelsEn } from "./MenuLabelsEn"

export class LabelsEn extends AbstractLocaleMessageObject {
  readonly menuLabels: MenuLabelsEn = new MenuLabelsEn()

  readonly authoriModelList: AuthoriModelListEn = new AuthoriModelListEn()
}
