import { Vue } from "vue-property-decorator"

import { EVENT_CALL_SCREEN_API } from "@/common/eventdefs"
import CommunicatorOptions from "@/common/interfaces/CommunicatorOptions"
import { MenuItem } from "@/models/topMenu/MenuItem"
import { ScreenPermissionListPostRequest } from "@/oplux/v3/api/screen/ScreenPermissionListPostRequest"

import { opluxmenu } from "@@/common/opluxmenu"

/**
 * Menuのユーティリティ
 */
export class MenuUtils {
  /**
   * 再帰的にメニューの画面IDを集める
   * @param menuItems 対象のMenuItem配列
   * @return 集めた画面IDの配列
   */
  static recursiveSearchScreenId(menuItems: MenuItem[]): string[] {
    return menuItems.reduce((acc: string[], val: MenuItem) => {
      if (val.screenId) {
        acc.push(val.screenId)
      }
      if (val.children) {
        acc = acc.concat(this.recursiveSearchScreenId(val.children))
      }
      return acc
    }, [])
  }

  /**
   * メニューの権限情報の準備をする
   */
  static prepareMenuPermissions(component: Vue): void {
    // screenIdを集める
    const screenIds = this.recursiveSearchScreenId(opluxmenu)

    // 権限の問い合わせを行う
    const requestInfo = new ScreenPermissionListPostRequest()
    requestInfo.screenIds = screenIds
    const options: CommunicatorOptions = {
      manualHandling: false,
      extraArg: "menu",
    }
    component.$root.$emit(EVENT_CALL_SCREEN_API, requestInfo, options)
  }
}
