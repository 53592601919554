import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CombinatJudgmentDetailJa extends AbstractLocaleMessageObject {
  readonly companyId: string = "加盟店ID"

  readonly authoriModelId: string = "審査モデルID"

  readonly description: string = "説明"

  readonly createdAt: string = "作成日時"

  readonly updatedAt: string = "更新日時"

  readonly updatedBy: string = "更新ユーザー"

  readonly releasedAt: string = "本番リリース日時"

  readonly rulePackageId: string = "ルールパッケージID"

  readonly authKind: string = "審査種別"

  readonly expirationDate: string = "有効期間"

  readonly group: string = "グループ"

  readonly unitAmount: string = "ユニット数"

  readonly totalRuleAmount: string = "総ルール数"

  readonly threshold: string = "閾値"

  readonly ruleAmount: string = "ルール数"

  readonly ruleGroupJudgmentDefault: string = "ルールグループ判定既定値"

  readonly editBtn: string = "編集"

  readonly backBtn: string = "戻る"

  readonly judgementTypeOk: string = "OK"

  readonly judgementTypeNg: string = "NG"

  readonly judgementTypeHold: string = "HOLD"

  readonly judgementTypeReview: string = "REVIEW"

  readonly logicalConditionAnd: string = "AND"

  readonly logicalConditionOr: string = "OR"

  readonly logicalConditionCount: string = "COUNT"
}
