import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterExpressionJa extends AbstractLocaleMessageObject {
  readonly expressionLabel: string = "条件式"

  readonly lhsLabel: string = "左辺"

  readonly rhsLabel: string = "右辺"

  readonly editBtn: string = "編集"

  readonly tableColumnBtn: string = "テーブルカラム"

  readonly rpColumnBtn: string = "リクエストパラメータ"

  readonly fromSharingRuleBtn: string = "共有ルールパーツから挿入"

  readonly toSharingRuleBtn: string = "共有ルールパーツへ追加"

  readonly conditionBtn: string = "条件式グループ"

  readonly functionOptionLabel: string = "関数オプション"

  readonly functionLabel: string = "関数"

  readonly functionListLoadingLabel: string = "関数リストを読み込み中..."

  readonly functionListLoadErrorMessage: string = "関数リストの取得に失敗しました"

  readonly argLabel: string = "引数"

  readonly message01: string = "※複数の場合は、カンマ「,」で区切ります"

  readonly clearBtn: string = "クリア"

  readonly message02: string = "が"

  readonly message03: string = "と"

  readonly opeLabel: string = "演算子"

  readonly arrayLabel: string = "商品を配列で参照"

  readonly compLabel: string = "比較タイプ"

  readonly strLabel: string = "文字列"

  readonly numLabel: string = "数値"

  readonly dateLabel: string = "日付"

  readonly datetimeLabel: string = "日時"

  readonly message04: string = "※START_WITH以降は文字列タイプのみ有効です。"

  readonly message05: string = "※IS_NULL/IS_NOT_NULLは右辺は使えません。"

  readonly message06: string = "（選択時に、右辺の入力内容はクリアされます）"

  readonly description: string = "説明"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly equal: string = "= (EQUAL)"

  readonly notEqual: string = "!= (NOT_EQUAL)"

  readonly lessThan: string = "< (LESS_THAN)"

  readonly greaterThan: string = "> (GREATER_THAN)"

  readonly lessThanOrEqual: string = "<= (LESS_THAN_OR_EQUAL)"

  readonly greaterThanOrEqual: string = ">= (GREATER THAN_OR_EQUAL)"

  readonly isNull: string = "左が空である(IS_NULL)"

  readonly isNotNull: string = "左が空でない(IS_NOT_NULL)"

  readonly startsWith: string = "左が右から始まる(STARTS_WITH)"

  readonly endsWith: string = "左が右で終わる(ENDS_WITH)"

  readonly contain: string = "左が右を含む(CONTAIN)"

  readonly notContain: string = "左が右を含まない(NOT_CONTAIN)"

  readonly isContain: string = "右が左を含む(IS_CONTAIN)"

  readonly isNotContain: string = "右が左を含まない(IS_NOT_CONTAIN)"

  readonly regexpMatch: string = "左が右とマッチする(REGEXP_MATCH)"

  readonly notRegexpMatch: string = "左が右とマッチしない(NOT_ REGEXP_MATCH)"

  readonly notStartsWith: string = "左が右から始まらない(NOT_STARTS_WITH)"

  readonly notEndsWith: string = "左が右から終わらない(NOT_ENDS_WITH)";

  readonly in: string = "IN"

  readonly notIn: string = "NOT IN"

  readonly like: string = "LIKE"

  readonly notLike: string = "NOT LIKE"

  readonly regex: string = "REGEX"

  readonly notRegex: string = "NOT REGEX"

  readonly ruleDescriptionPH: string = "200文字以内で入力してください。"

  readonly constBtn: string = "定数"

  readonly strItemPH: string = "文字列を入力してください。"

  readonly numberItemPH: string = "数値を入力してください。"

  readonly dateValidationProviderName: string = "日時"

  readonly hourValidationProviderName: string = "時"

  readonly minuteValidationProviderName: string = "分"

  readonly rpPrefix: string = "RP："

  readonly tblPrefix: string = "TBL："

  readonly cgPrefix: string = "CG："

  readonly constPrefix: string = "定数："
}
