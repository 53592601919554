import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [RuleMasterRelatedEventExtractionPartsEdit]。
 */
export const RuleMasterRelatedEventExtractionPartsEditRouteConfig: RouteConfig = {
  path: "/RuleMasterRelatedEventExtractionPartsEdit",
  name: "RuleMasterRelatedEventExtractionPartsEdit",
  component: () =>
    import("@@/views/ruleMasterRelatedEventExtractionPartsEdit/RuleMasterRelatedEventExtractionPartsEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
