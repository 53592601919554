import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CompanyEdit]。
 */
export const CompanyEditRouteConfig: RouteConfig = {
  path: "/CompanyEdit",
  name: "CompanyEdit",
  component: () => import("@@/views/companyEdit/CompanyEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
