import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { AdminAddJa } from "./AdminAddJa"
import { AdminDetailJa } from "./AdminDetailJa"
import { AdminEditJa } from "./AdminEditJa"
import { AdminListJa } from "./AdminListJa"
import { AdminRoleAddJa } from "./AdminRoleAddJa"
import { AdminRoleDetailJa } from "./AdminRoleDetailJa"
import { AdminRoleEditJa } from "./AdminRoleEditJa"
import { AdminRoleListJa } from "./AdminRoleListJa"
import { AuthoriModelAddJa } from "./AuthoriModelAddJa"
import { AuthoriModelDetailJa } from "./AuthoriModelDetailJa"
import { AuthoriModelEditJa } from "./AuthoriModelEditJa"
import { AuthoriModelincludingRuleList } from "./AuthoriModelincludingRuleList"
import { AuthoriModelListJa } from "./AuthoriModelListJa"
import { CombinatJudgmentDetailJa } from "./CombinatJudgmentDetailJa"
import { CombinatJudgmentEditJa } from "./CombinatJudgmentEditJa"
import { CommonAllSelectionPartsJa } from "./CommonAllSelectionPartsJa"
import { CommonConfirmDialogJa } from "./CommonConfirmDialogJa"
import { CommonDateSelectionFieldJa } from "./CommonDateSelectionFieldJa"
import { CommonDateTimeSelectionFieldJa } from "./CommonDateTimeSelectionFieldJa"
import { CommonMessageJa } from "./CommonMessageJa"
import { CommonPagingControlPartsJa } from "./CommonPagingControlPartsJa"
import { CommonTitleHeaderJa } from "./CommonTitleHeaderJa"
import { CompanyAddJa } from "./CompanyAddJa"
import { CompanyDetailJa } from "./CompanyDetailJa"
import { CompanyEditJa } from "./CompanyEditJa"
import { CompanyListJa } from "./CompanyListJa"
import { EventCsvDownloadDlgJa } from "./EventCsvDownloadDlgJa"
import { EventDetailJa } from "./EventDetailJa"
import { EventListJa } from "./EventListJa"
import { KeywordAddJa } from "./KeywordAddJa"
import { KeywordListJa } from "./KeywordListJa"
import { LabelAddEdit } from "./LabelAddEdit"
import { LabelListJa } from "./LabelListJa"
import { LoginInputJa } from "./LoginInputJa"
import { MenuLabelsJa } from "./MenuLabelsJa"
import { OperatorAddJa } from "./OperatorAddJa"
import { OperatorDetailJa } from "./OperatorDetailJa"
import { OperatorEditJa } from "./OperatorEditJa"
import { OperatorListJa } from "./OperatorListJa"
import { OperatorRoleAddJa } from "./OperatorRoleAddJa"
import { OperatorRoleDetailJa } from "./OperatorRoleDetailJa"
import { OperatorRoleEditJa } from "./OperatorRoleEditJa"
import { OperatorRoleListJa } from "./OperatorRoleListJa"
import { PasswordChangeJa } from "./PasswordChangeJa"
import { PasswordChangeRequestJa } from "./PasswordChangeRequestJa"
import { RelatedEventExtractionPartsSelectDlgJa } from "./RelatedEventExtractionPartsSelectDlgJa"
import { ReleaseRulePackageDetailJa } from "./ReleaseRulePackageDetailJa"
import { RuleMasterConditionJa } from "./RuleMasterConditionJa"
import { RuleMasterConstantDlgJa } from "./RuleMasterConstantDlgJa"
import { RuleMasterDBColumnDlgJa } from "./RuleMasterDBColumnDlgJa"
import { RuleMasterDetailJa } from "./RuleMasterDetailJa"
import { RuleMasterExpressionJa } from "./RuleMasterExpressionJa"
import { RuleMasterListJa } from "./RuleMasterListJa"
import { RuleMasterRelatedEventExtractionPartsEditJa } from "./RuleMasterRelatedEventExtractionPartsEditJa"
import { RuleMasterRequestParameterDlgJa } from "./RuleMasterRequestParameterDlgJa"
import { RuleMasterSharingRulePartsEditJa } from "./RuleMasterSharingRulePartsEditJa"
import { RulePackageListJa } from "./RulePackageListJa"
import { SharingRulePartsSelectDlgJa } from "./SharingRulePartsSelectDlgJa"
import { SystemErrorJa } from "./SystemErrorJa"
import { TopMenuBarJa } from "./TopMenuBarJa"

export class LabelsJa extends AbstractLocaleMessageObject {
  readonly adminAdd: AdminAddJa = new AdminAddJa()

  readonly adminDetail: AdminDetailJa = new AdminDetailJa()

  readonly adminEdit: AdminEditJa = new AdminEditJa()

  readonly adminList: AdminListJa = new AdminListJa()

  readonly adminRoleAdd: AdminRoleAddJa = new AdminRoleAddJa()

  readonly adminRoleDetail: AdminRoleDetailJa = new AdminRoleDetailJa()

  readonly adminRoleEdit: AdminRoleEditJa = new AdminRoleEditJa()

  readonly adminRoleList: AdminRoleListJa = new AdminRoleListJa()

  readonly authoriModelAdd: AuthoriModelAddJa = new AuthoriModelAddJa()

  readonly authoriModelDetail: AuthoriModelDetailJa = new AuthoriModelDetailJa()

  readonly authoriModelEdit: AuthoriModelEditJa = new AuthoriModelEditJa()

  readonly authoriModelincludingRuleList: AuthoriModelincludingRuleList = new AuthoriModelincludingRuleList()

  readonly authoriModelList: AuthoriModelListJa = new AuthoriModelListJa()

  readonly combinatJudgmentDetail: CombinatJudgmentDetailJa = new CombinatJudgmentDetailJa()

  readonly combinatJudgmentEdit: CombinatJudgmentEditJa = new CombinatJudgmentEditJa()

  readonly commonTitleHeader: CommonTitleHeaderJa = new CommonTitleHeaderJa()

  readonly companyAdd: CompanyAddJa = new CompanyAddJa()

  readonly companyDetail: CompanyDetailJa = new CompanyDetailJa()

  readonly companyEdit: CompanyEditJa = new CompanyEditJa()

  readonly companyList: CompanyListJa = new CompanyListJa()

  readonly eventCsvDownloadDlg: EventCsvDownloadDlgJa = new EventCsvDownloadDlgJa()

  readonly eventDetail: EventDetailJa = new EventDetailJa()

  readonly eventList: EventListJa = new EventListJa()

  readonly keywordAdd: KeywordAddJa = new KeywordAddJa()

  readonly keywordList: KeywordListJa = new KeywordListJa()

  readonly labelAddEdit: LabelAddEdit = new LabelAddEdit()

  readonly labelList: LabelListJa = new LabelListJa()

  readonly loginInput: LoginInputJa = new LoginInputJa()

  readonly menuLabels: MenuLabelsJa = new MenuLabelsJa()

  readonly operatorAdd: OperatorAddJa = new OperatorAddJa()

  readonly operatorDetail: OperatorDetailJa = new OperatorDetailJa()

  readonly operatorEdit: OperatorEditJa = new OperatorEditJa()

  readonly operatorList: OperatorListJa = new OperatorListJa()

  readonly operatorRoleAdd: OperatorRoleAddJa = new OperatorRoleAddJa()

  readonly operatorRoleDetail: OperatorRoleDetailJa = new OperatorRoleDetailJa()

  readonly operatorRoleEdit: OperatorRoleEditJa = new OperatorRoleEditJa()

  readonly operatorRoleList: OperatorRoleListJa = new OperatorRoleListJa()

  readonly passwordChange: PasswordChangeJa = new PasswordChangeJa()

  readonly passwordChangeRequest: PasswordChangeRequestJa = new PasswordChangeRequestJa()

  readonly relatedEventExtractionPartsSelectDlg: RelatedEventExtractionPartsSelectDlgJa =
    new RelatedEventExtractionPartsSelectDlgJa()

  readonly releaseRulePackageDetail: ReleaseRulePackageDetailJa = new ReleaseRulePackageDetailJa()

  readonly ruleMasterCondition: RuleMasterConditionJa = new RuleMasterConditionJa()

  readonly ruleMasterConstantDlg: RuleMasterConstantDlgJa = new RuleMasterConstantDlgJa()

  readonly ruleMasterDBColumnDlg: RuleMasterDBColumnDlgJa = new RuleMasterDBColumnDlgJa()

  readonly ruleMasterDetail: RuleMasterDetailJa = new RuleMasterDetailJa()

  readonly ruleMasterExpression: RuleMasterExpressionJa = new RuleMasterExpressionJa()

  readonly ruleMasterList: RuleMasterListJa = new RuleMasterListJa()

  readonly ruleMasterRelatedEventExtractionPartsEdit: RuleMasterRelatedEventExtractionPartsEditJa =
    new RuleMasterRelatedEventExtractionPartsEditJa()

  readonly ruleMasterRequestParameterDlg: RuleMasterRequestParameterDlgJa = new RuleMasterRequestParameterDlgJa()

  readonly ruleMasterSharingRulePartsEdit: RuleMasterSharingRulePartsEditJa = new RuleMasterSharingRulePartsEditJa()

  readonly rulePackageList: RulePackageListJa = new RulePackageListJa()

  readonly sharingRulePartsSelectDlg: SharingRulePartsSelectDlgJa = new SharingRulePartsSelectDlgJa()

  readonly commonDateSelectionField: CommonDateSelectionFieldJa = new CommonDateSelectionFieldJa()

  readonly topMenuBar: TopMenuBarJa = new TopMenuBarJa()

  readonly systemError: SystemErrorJa = new SystemErrorJa()

  readonly commonDateTimeSelectionField: CommonDateTimeSelectionFieldJa = new CommonDateTimeSelectionFieldJa()

  readonly commonAllSelectionParts: CommonAllSelectionPartsJa = new CommonAllSelectionPartsJa()

  readonly commonConfirmDialog: CommonConfirmDialogJa = new CommonConfirmDialogJa()

  readonly commonPagingControlParts: CommonPagingControlPartsJa = new CommonPagingControlPartsJa()

  readonly commonMessage: CommonMessageJa = new CommonMessageJa()
}
