import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterConditionJa extends AbstractLocaleMessageObject {
  readonly conditionLabel: string = "条件式グループ"

  readonly eventLabel: string = "イベント"

  readonly historySearchLabel: string = "履歴検索"

  readonly historySearchOptionLabel: string = "履歴検索オプション"

  readonly tableTypeLabel: string = "テーブルタイプ"

  readonly tableTypeEventLabel: string = "イベント系"

  readonly tableTypeEachLabel: string = "個別判定系"

  readonly resultTypeLabel: string = "結果タイプ"

  readonly invalidLabel: string = "無効フラグ"

  readonly historyColumnLabel: string = "集計カラム"

  readonly tableColumnBtn: string = "テーブルカラム"

  readonly rpColumnBtn: string = "リクエストパラメータ"

  readonly clearBtn: string = "クリア"

  readonly historyDistinctLabel: string = "重複除外"

  readonly historyLimitLabel: string = "行数制限"

  readonly offsetLabel: string = "オフセット"

  readonly sortColumnLabel: string = "ソートカラム"

  readonly sortOrderLabel: string = "ソート順"

  readonly fromSharingRuleBtn: string = "共有ルールパーツから挿入"

  readonly toSharingRuleBtn: string = "共有ルールパーツへ追加"

  readonly conditionBtn: string = "条件式グループ追加"

  readonly expressionBtn: string = "条件式追加"

  readonly expressionsLabel: string = "条件式リスト"

  readonly logicalOperation: string = "条件式の関係"

  readonly editBtn: string = "項目編集"

  readonly previousBtn: string = "上へ"

  readonly nextBtn: string = "下へ"

  readonly description: string = "説明"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly countLabel: string = "Count"

  readonly sumLabel: string = "Sum"

  readonly listLabel: string = "List"

  readonly hourLabel: string = "Hour"

  readonly dayOfWeekLabel: string = "DayOfWeek"

  readonly sleepLabel: string = "Sleep"

  readonly andLabel: string = "AND"

  readonly orLabel: string = "OR"

  readonly notLabel: string = "NOT"

  readonly unionLabel: string = "UNION"

  readonly sortOrderFalse: string = "昇順"

  readonly sortOrderTrue: string = "降順"

  readonly expressionLabel: string = "条件式"

  readonly ruleDescriptionPH: string = "200文字以内で入力してください。"

  readonly booleanType: string = "Boolean"

  readonly stringType: string = "String"

  readonly numberType: string = "Number"
}
