import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AdminRoleEdit]。
 */
export const AdminRoleEditRouteConfig: RouteConfig = {
  path: "/AdminRoleEdit",
  name: "AdminRoleEdit",
  component: () => import("@@/views/adminRoleEdit/AdminRoleEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
