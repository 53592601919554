
import { Component, Vue } from "vue-property-decorator"

import { API_ERROR_HANDRING, EVENT_CALL_RULESTUDIO_API, EVENT_CALL_SCREEN_API } from "@/common/eventdefs"
import { Const, ErrorCode } from "@/common/globals"
import CommunicatorOptions from "@/common/interfaces/CommunicatorOptions"
import { AbstractRequest } from "@/oplux/v3/api/common/AbstractRequest"
import { ErrorItem } from "@/oplux/v3/api/common/valueobject/ErrorItem"
import { commonOverlayModule } from "@/stores/modules/CommonOverlayModule"
import { SnackbarType, SnackbarUtils } from "@/utils/SnackbarUtils"
import { TransitSystemErrorType, TransitUtils } from "@/utils/TransitUtils"

import { ApiClient } from "@@/common/ApiClient"
import { LoginInputRouteConfig } from "@@/views/login/LoginInputRouteConfig"

/**
 * ajax通信を司るコンポーネントです。
 * このコンポーネントは直接呼ばずに、イベントを投げることで起動してください。
 */
@Component
export default class Communicator extends Vue {
  created() {
    this.$root.$on(EVENT_CALL_SCREEN_API, this.send)
    this.$root.$on(EVENT_CALL_RULESTUDIO_API, this.send)
    this.$root.$on(API_ERROR_HANDRING, this.handleErrors)
  }

  render() {
    return
  }

  async send(req: AbstractRequest, options: CommunicatorOptions | undefined = undefined) {
    let communicatorContextStatus = Const.CommunicatorContextStatusError
    try {
      if (req === null || req === undefined) {
        /* typescript では不要だったりする？ by tueda */
        throw new Error("Communicator#send: AbstractRequest is not specified.")
      }

      if (options) {
        if (options.showOverlay === true) {
          commonOverlayModule.changeOverlay(true)
        }
        if (options.context) {
          options.context.setStateExecuting()
        }
      }
      const apiResult = await new ApiClient().send(req, {
        handlingAuthorizeError: false,
        showMessages: false,
        showErrorMessages: false,
      })
      const dispatchType = req.getStoreActionId(options)
      if (options && options.manualHandling) {
        // マニュアルハンドリングの場合
        // エラーが無い、またはハンドリングされたエラーが無い
        if (apiResult.hasErrors() === false || this.handleErrors(apiResult.errors, options) === false) {
          // レスポンスを全て提供する
          this.$store.dispatch(dispatchType, {
            commonResponse: apiResult.commonResponse,
            extraArg: options.extraArg,
          })
        }
        if (apiResult.hasErrors() === false) {
          communicatorContextStatus = Const.CommunicatorContextStatusSuccess
        }
      } else {
        // マニュアルハンドリングでなければエラー判定を行う
        if (apiResult.hasErrors()) {
          // エラー時でも遷移しないフラグをオプションから取得
          this.handleErrors(apiResult.errors, options)
        } else {
          // 正常
          // infoメッセージ表示
          if (apiResult.hasMessages()) {
            if (options && options.noSnackbarOnSuccess) {
              // NOTE: ESLint の Empty block statement no-empty を回避するための応急処置
            } else {
              SnackbarUtils.showMessages(apiResult.messages)
            }
          }
          const apiTelegram = apiResult.apiTelegram
          if (options && options.extraArg) {
            this.$store.dispatch(dispatchType, {
              apiTelegram,
              extraArg: options.extraArg,
            })
          } else {
            this.$store.dispatch(dispatchType, apiTelegram)
          }
          communicatorContextStatus = Const.CommunicatorContextStatusSuccess
        }
      }
    } catch (exception) {
      console.error(exception)

      if (options && options.showInfoOnlyOnException === true) {
        // スナックバーを表示
        SnackbarUtils.showSnackbar(SnackbarType.INFO, this.$t("messages.info697") as string)
      } else {
        // SystemError画面に遷移
        TransitUtils.transitToSystemError(
          this,
          TransitSystemErrorType.OTHER_ERRORS,
          "SYSTEM ERROR (Communicator exception)"
        )
      }
    } finally {
      if (options) {
        if (options.showOverlay === true) {
          commonOverlayModule.changeOverlay(false)
        }
        if (options.context) {
          options.context.status = communicatorContextStatus
        }
      }
    }
  }

  /**
   * エラーハンドリング
   */
  private handleErrors(errors: Array<ErrorItem>, options: CommunicatorOptions | undefined = undefined) {
    const isManualHandling = options && options.manualHandling ? true : false
    let errorHandled = false
    let transitToLoginInput = false
    let transitToLoginInputOnly = false
    let invalidDataDuplicate = false
    let noTransitionOnError = false
    let fatalError = false
    if (options !== undefined && options.noTransitionOnError) {
      noTransitionOnError = true
    }

    // エラーコードに応じた判定
    errors.forEach(error => {
      if (error.code === ErrorCode.AuthorizeErrorInvalidToken) {
        // トークン無効
        transitToLoginInputOnly = true
        errorHandled = true
      } else if (error.code === ErrorCode.AuthorizeErrorSourceIpNotAllowed) {
        // IP認証エラー
        transitToLoginInput = true
        errorHandled = true
      } else if (error.code === ErrorCode.InvalidDataDuplicate) {
        // 重複エラー
        invalidDataDuplicate = true
        errorHandled = true
      } else if (error.code === ErrorCode.ServerErrorInternalFailure) {
        // サーバー内部エラー
        fatalError = true
        errorHandled = true
      }
    })

    // 状況に応じて画面遷移
    if (fatalError) {
      // 致命的なエラーは他に優先してエラー表示とシステムエラー画面への遷移を行う
      SnackbarUtils.queueingErrors(errors)
      TransitUtils.transitToSystemError(this, TransitSystemErrorType.API_RESPONSE_ERROR)
    } else if (transitToLoginInputOnly) {
      this.$router.push(LoginInputRouteConfig.path)
    } else if (transitToLoginInput) {
      // 今の画面に応じてメッセージの表示手法を変更
      if (this.$router.currentRoute.path === LoginInputRouteConfig.path) {
        SnackbarUtils.showErrors(errors)
      } else {
        SnackbarUtils.queueingErrors(errors)
      }
      this.$router.push(LoginInputRouteConfig.path)
    } else if (invalidDataDuplicate) {
      // errorメッセージ表示
      SnackbarUtils.showErrors(errors)
    } else if (!isManualHandling) {
      // errorメッセージ表示
      SnackbarUtils.showErrors(errors)
      if (noTransitionOnError === false) {
        // SystemError画面に遷移
        TransitUtils.transitToSystemError(this, TransitSystemErrorType.API_RESPONSE_ERROR)
      }
    }

    return errorHandled
  }
}
