import { CommonRequest } from "@/oplux/v3/api/common/valueobject/CommonRequest"

/**
 * ルールスタジオ用のtelegramです。
 */
export class RuleStudioProxyTelegram {
  /**
   * 呼び出したいルールスタジオ側のAPI名
   */
  api: string = ""

  /**
   * 呼び出したいルールスタジオ側のAPIのmethod
   */
  method: string = ""

  /**
   * 呼び出したいルールスタジオ側のAPIに渡す情報
   */
  request: CommonRequest = new CommonRequest()
}
