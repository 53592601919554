import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CombinatJudgmentAdd]。
 */
export const CombinatJudgmentAddRouteConfig: RouteConfig = {
  path: "/rule-packages/:companyId/:authoriModelId/new",
  name: "CombinatJudgmentAdd",
  component: () => import("@@/views/combinatJudgmentEdit/CombinatJudgmentAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
