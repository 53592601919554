import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorRoleEdit]。
 */
export const OperatorRoleEditRouteConfig: RouteConfig = {
  path: "/OperatorRoleEdit",
  name: "OperatorRoleEdit",
  component: () => import("@@/views/operatorRoleEdit/OperatorRoleEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
