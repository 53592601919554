import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AdminEdit]。
 */
export const AdminEditRouteConfig: RouteConfig = {
  path: "/AdminEdit",
  name: "AdminEdit",
  component: () => import("@@/views/adminEdit/AdminEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
