import axios, { AxiosResponse, Method } from "axios"

import { API_ERROR_HANDRING } from "@/common/eventdefs"
import { Const, ErrorCode } from "@/common/globals"
import { getLoginInfo } from "@/infrastructures"
import { AbstractRequest } from "@/oplux/v3/api/common/AbstractRequest"
import { AbstractResponse } from "@/oplux/v3/api/common/AbstractResponse"
import { ApiResult } from "@/oplux/v3/api/common/ApiResult"
import { CommonRequest } from "@/oplux/v3/api/common/valueobject/CommonRequest"
import { CommonResponse } from "@/oplux/v3/api/common/valueobject/CommonResponse"
import { ErrorItem } from "@/oplux/v3/api/common/valueobject/ErrorItem"
import { MessageItem } from "@/oplux/v3/api/common/valueobject/MessageItem"
import { RequestHeader } from "@/oplux/v3/api/common/valueobject/RequestHeader"
import { RuleStudioProxyRequest } from "@/oplux/v3/api/common/valueobject/RuleStudioProxyRequest"
import { RuleStudioProxyTelegram } from "@/oplux/v3/api/common/valueobject/RuleStudioProxyTelegram"
import { ScreenApiRequestHeader } from "@/oplux/v3/api/common/valueobject/ScreenApiRequestHeader"
import { RuleStudioProxyPostResponse } from "@/oplux/v3/api/screen/RuleStudioProxyPostResponse"
import { commonOverlayModule } from "@/stores/modules/CommonOverlayModule"
import { SnackbarUtils } from "@/utils/SnackbarUtils"

import { vueInstance } from "@@/main"

type ApiClientOptions = {
  /** 認証エラーをハンドリングするかの設定 */
  handlingAuthorizeError: boolean
  /** メッセージがあった場合に表示するかの設定 */
  showMessages: boolean
  /** エラーメッセージがあった場合に表示するかの設定 */
  showErrorMessages: boolean
  /** リクエスト中にオーバーレイを表示するかの設定 */
  showOverlay: boolean
}

/**
 * レガシー画面API（blanco が生成した API と同じ認証方法の API）の呼び出しに使う
 */
export class ApiClient {
  /**
   * API呼び出しのEntryポイント
   */
  async send<T extends AbstractResponse | RuleStudioProxyPostResponse<T>>(
    request: AbstractRequest,
    {
      handlingAuthorizeError = true,
      showMessages = true,
      showErrorMessages = true,
      showOverlay = false,
    }: Partial<ApiClientOptions> = {}
  ): Promise<ApiResult<T>> {
    request.trimString()
    const url = this.makeUrl(request)
    const body = await this.makeBody(request)
    let commonResponse: CommonResponse<T> | undefined
    const errors = new Array<ErrorItem>()
    const messages = new Array<MessageItem>()

    if (showOverlay) {
      commonOverlayModule.changeOverlay(true)
    }
    if (!process.env.VUE_APP_API_ENDPOINT) {
      /* JSON ファイルを取得する */
      const res = await this.callAxios<T>(
        this.getJsonFileName(request),
        "GET", // 静的ファイルを取得するため GET メソッド固定
        body
      )
      commonResponse = res.data
    } else {
      const method = request.requestMetaInfo().method
      const res = await this.callAxios<T>(
        url,
        request.toRuleStudio() ? "POST" : method, // ルールスタジオは現状POSTしか受け付けない
        body
      )
      // ルールスタジオのレスポンスはカプセル化解除する
      if (request.toRuleStudio()) {
        const outerRes = res.data
        if (outerRes.errors) {
          outerRes.errors.forEach(item => {
            errors.push(item)
          })
        }
        if (
          outerRes.telegram &&
          this.isRuleStudioProxyPostResponse<T>(outerRes.telegram) &&
          outerRes.telegram.response
        ) {
          commonResponse = outerRes.telegram.response
        }
      } else {
        commonResponse = res.data
      }
    }
    if (showOverlay) {
      commonOverlayModule.changeOverlay(false)
    }

    if (commonResponse) {
      if (commonResponse.messages && commonResponse.messages.length !== 0) {
        commonResponse.messages.forEach(message => messages.push(message))
      }
      if (commonResponse.errors && commonResponse.errors.length !== 0) {
        commonResponse.errors.forEach(item => {
          errors.push(item)
        })
      }
    }

    if (
      handlingAuthorizeError &&
      errors.filter(
        e => e.code === ErrorCode.AuthorizeErrorInvalidToken || e.code === ErrorCode.AuthorizeErrorSourceIpNotAllowed
      ).length > 0
    ) {
      // 認証エラー時の処理は Communicator の既存処理を使う
      vueInstance.$emit(API_ERROR_HANDRING, errors)
      return new ApiResult<T>(commonResponse, messages, errors)
    }

    if (showMessages && messages.length > 0) {
      SnackbarUtils.showMessages(messages)
    }
    if (showErrorMessages && errors.length > 0) {
      SnackbarUtils.showErrors(errors)
    }

    return new ApiResult<T>(commonResponse, messages, errors)
  }

  /**
   * デバグ用JSONの読込ルーチン。
   * JSON ファイルは
   * public/json/api/電文処理ID/電文ID.json
   * とし、1 API につき固定の 1 JSON を返す。
   */
  private getJsonFileName(request: AbstractRequest): string {
    const requestObjClassName = request.telegramId()
    // 接尾辞「Request」の存在を確定させて、電文処理IDを作る
    let processName: string
    const length = requestObjClassName.length
    if (requestObjClassName.endsWith(Const.PostRequestSuffix)) {
      processName = requestObjClassName.substring(0, length - Const.PostRequestSuffix.length)
    } else if (requestObjClassName.endsWith(Const.PutRequestSuffix)) {
      processName = requestObjClassName.substring(0, length - Const.PutRequestSuffix.length)
    } else {
      throw new Error("Unexpected value. " + requestObjClassName)
    }
    const method = request.requestMetaInfo().method
    return "/jsons/api/" + processName + "/" + method + "CommonResponse.json"
  }

  private makeUrl(request: AbstractRequest) {
    if (request.toRuleStudio()) {
      return process.env.VUE_APP_API_ENDPOINT + "/api/v3/screen/ruleStudioProxy"
    } else {
      return process.env.VUE_APP_API_ENDPOINT + request.requestMetaInfo().uri
    }
  }

  private async makeBody(request: AbstractRequest) {
    // ルールスタジオ用と他では処理を分ける
    const _request = request.toRuleStudio()
      ? await this.makeRuleStudioProxyRequest(request)
      : await this.makeCommonRequest(request)

    return JSON.stringify(_request)
  }
  /**
   * 電文をAPIのエンベロープ（CommonRequest）でくるみます。
   * header 情報（token の設定など）もここで行います。
   */
  private async makeCommonRequest(request: AbstractRequest): Promise<CommonRequest> {
    const info = await this.prepareScreenApiRequestHeader(request)

    const commonRequest = new CommonRequest()
    commonRequest.info = info
    commonRequest.telegram = request

    return commonRequest
  }

  /**
   * ルールスタジオ用の電文をAPIのエンベロープでくるみます。
   * header 情報（token の設定など）もここで行います。
   */
  private async makeRuleStudioProxyRequest(request: AbstractRequest): Promise<RuleStudioProxyRequest> {
    const info = await this.prepareScreenApiRequestHeader(request)

    const reqMeta = request.requestMetaInfo()
    const splittedUrlArray = reqMeta.uri.split("/")
    const api = splittedUrlArray[splittedUrlArray.length - 1]

    const commonRequest = new CommonRequest()
    commonRequest.info = new RequestHeader()
    commonRequest.telegram = request

    const telegram = new RuleStudioProxyTelegram()
    telegram.api = api
    telegram.method = reqMeta.method
    telegram.request = commonRequest

    const proxyRequest = new RuleStudioProxyRequest()
    proxyRequest.info = info
    proxyRequest.telegram = telegram

    return proxyRequest
  }

  private async prepareScreenApiRequestHeader(request: AbstractRequest): Promise<ScreenApiRequestHeader> {
    const header = new ScreenApiRequestHeader()

    // 認証が必要なAPIの場合はトークンの取得が先
    if (request.requestMetaInfo().isAuthenticationRequire) {
      // router でログインチェックしてるので null にならない
      const loginInfo = getLoginInfo()!
      header.token = loginInfo.loginToken
      header.shopId = "cacco"
    }

    return header
  }

  private async callAxios<T extends AbstractResponse>(
    url: string,
    method: string,
    body: string
  ): Promise<AxiosResponse<CommonResponse<T>>> {
    try {
      return await axios.request<CommonResponse<T>>({
        url,
        method: method as Method,
        headers: {
          "content-type": "application/json",
        },
        data: body,
      })
    } catch (exception) {
      if (axios.isAxiosError(exception) && exception.response) {
        return exception.response
      }
      throw exception
    }
  }

  private isRuleStudioProxyPostResponse<T extends AbstractResponse>(
    telegram: any
  ): telegram is RuleStudioProxyPostResponse<T> {
    return telegram.response !== undefined
  }
}
