import { RouteConfig } from "vue-router"

export const RulePackageBulkEditAddConfig: RouteConfig = {
  path: "/rule-package-bulk-edit-add",
  name: "RulePackageBulkEditAdd",
  component: () => import("@@/views/rulePackageBulkEditAdd/RulePackageBulkEditAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
  props: true,
}
