import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterSharingRulePartsEditJa extends AbstractLocaleMessageObject {
  readonly sharingRulePartsCategoryList: string = "カテゴリリスト"

  readonly sharingRulePartsCategoryInfo: string = "カテゴリ情報"

  readonly ruleCategoryName: string = "カテゴリ名"

  readonly addBtn: string = "追加"

  readonly conditionList: string = "条件式リスト"

  readonly copyBtn: string = "複写"

  readonly ruleInfoConditionGroupAdd: string = "条件式グループ追加"

  readonly ruleInfoConditionAdd: string = "条件式追加"

  readonly infoCategoryScreenInfo: string = "共有ルールパーツカテゴリ情報"

  readonly infoScreenInfo: string = "共有ルールパーツ情報"

  readonly titleDlg: string = "共有ルールパーツ複写"

  readonly copyCategoryName: string = "複写先カテゴリ名"

  readonly ruleInfoConditionDescription: string = "条件式の説明"

  readonly titleConfirmationDlg: string = "条件式グループ確認ダイアログ"

  readonly confirmationMessage1: string = "条件式グループのタイプを選択してください。"

  readonly confirmationMessage2: string = "条件式グループのタイプは、下記になります。"

  readonly event: string = "イベント"

  readonly historySearch: string = "履歴検索"
}
