import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterRequestParameterDlgJa extends AbstractLocaleMessageObject {
  readonly rpColumnLabel: string = "リクエストパラメータ選択"

  readonly rpNameLabel: string = "パラメータ名"

  readonly rpCodeLabel: string = "パラメータコード"

  readonly searchBtn: string = "検索"

  readonly columnNameLabel: string = "リクエストパラメータ カラムリスト"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly rpLabel: string = "リクエストパラメータ"

  readonly searchItemPH: string = "パラメータ名またはパラメータコードを入力して検索"
}
