import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AuthoriModelincludingRuleList extends AbstractLocaleMessageObject {
  readonly ruleId: string = "ルールID"

  readonly version: string = "バーション"

  readonly ruleCode: string = "ルールコード"

  readonly ruleDescription: string = "ルール説明"

  readonly memo: string = "メモ"

  readonly updatedAt: string = "更新日時"

  readonly companyId: string = "加盟店ID"

  readonly authoriModelId: string = "審査モデルID"

  readonly belongRuleGroupInfoList: string = "ルールグループ名/ユニット番号"

  readonly authKind: string = "審査種別"

  readonly rulePackageId: string = "ルールパッケージID"

  readonly noNeedForBulkRelease: string = "一括リリース不要"

  readonly expirationDateStart: string = "有効期間開始日時"

  readonly expirationDateEnd: string = "有効期間終了日時"

  readonly rulePackageDescription: string = "説明"

  readonly btnBack: string = "戻る"

  readonly noData: string = "データはありません"
}
