
import { Component, Vue } from "vue-property-decorator"

import { ComponentUtils } from "@@/utils/ComponentUtils"

@Component
export default class FrameworkCommon extends Vue {
  render(createElement: Vue.CreateElement) {
    return createElement("div", { class: "framework-common-component" }, [ComponentUtils.loadDefaults(createElement)])
  }
}
