import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminRoleEditJa extends AbstractLocaleMessageObject {
  readonly roleId: string = "ロールID"

  readonly roleName: string = "ロール名"

  readonly functionGroup: string = "利用可能機能グループ"

  readonly editAddLabel: string = "編集・登録"

  readonly can: string = "できる"

  readonly canNot: string = "できない"

  readonly notProvideFunction: string = "機能を提供しない"

  readonly updateButton: string = "更新"

  readonly deleteButton: string = "削除"

  readonly cancelButton: string = "キャンセル"

  readonly okButton: string = "OK"

  readonly infoScreenInfo: string = "かっこユーザーロール情報"
}
