import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterDetailJa extends AbstractLocaleMessageObject {
  readonly ruleId: string = "ルールID"

  readonly ruleCode: string = "ルールコード"

  readonly description: string = "ルール説明"

  readonly memo: string = "メモ"

  readonly updatedAt: string = "更新日時"

  readonly updatedBy: string = "更新ユーザー"

  readonly version: string = "Ver."

  readonly firedNotifyFlag: string = "発動通知フラグ"

  readonly touchpoints: string = "タッチポイント"

  readonly editBtn: string = "編集"

  readonly backBtn: string = "戻る"

  readonly conditionLabel: string = "ルール条件関係図"

  readonly ruleCodePH: string = "100文字以内で入力してください。"

  readonly ruleDescriptionPH: string = "200文字以内で入力してください。"

  readonly ruleMemoPH: string = "200文字以内で入力してください。"

  readonly ruleDescriptionMessage: string = "説明がありません。"

  readonly ruleLabel: string = "ルール"

  readonly ruleCheckErrorDefaultMessage: string = "ルールに不整合があります。"
}
