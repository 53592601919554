import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CombinatJudgmentDetail]。
 */
export const CombinatJudgmentDetailRouteConfig: RouteConfig = {
  /**
   * RouteConfigのパラメータです: [path]。
   */
  path: "/rule-packages/:rulePackageId",

  /**
   * RouteConfigのパラメータです: [name]。
   *  api-screenで画面のアクセス権限を制御する際に参照
   *  DBの値に依存しているため、変更する際はDBの値も同期すること
   */
  name: "CombinatJudgmentDetail",

  /**
   * RouteConfigのパラメータです: [component]。
   */
  component: () => import("@@/views/combinatJudgmentDetail/CombinatJudgmentDetail.vue"),

  /**
   * RouteConfigのパラメータです: [meta]。
   */
  meta: {
    reload: false,
    authRequired: true,
  },
}
