import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorRoleAdd]。
 */
export const OperatorRoleAddRouteConfig: RouteConfig = {
  path: "/OperatorRoleAdd",
  name: "OperatorRoleAdd",
  component: () => import("@@/views/operatorRoleAdd/OperatorRoleAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
