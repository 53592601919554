import { RouteConfig } from "vue-router"

export const ReleaseInfoListRouteConfig: RouteConfig = {
  path: "/release-info",
  name: "release-info-list",
  component: () => import("@@/views/releaseInfoList/ReleaseInfoList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
