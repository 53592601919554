import { RouteConfig } from "vue-router"

import { AdminAddRouteConfig } from "@@/views/adminAdd/AdminAddRouteConfig"
import { AdminDetailRouteConfig } from "@@/views/adminDetail/AdminDetailRouteConfig"
import { AdminEditRouteConfig } from "@@/views/adminEdit/AdminEditRouteConfig"
import { AdminListRouteConfig } from "@@/views/adminList/AdminListRouteConfig"
import { AdminRoleAddRouteConfig } from "@@/views/adminRoleAdd/AdminRoleAddRouteConfig"
import { AdminRoleDetailRouteConfig } from "@@/views/adminRoleDetail/AdminRoleDetailRouteConfig"
import { AdminRoleEditRouteConfig } from "@@/views/adminRoleEdit/AdminRoleEditRouteConfig"
import { AdminRoleListRouteConfig } from "@@/views/adminRoleList/AdminRoleListRouteConfig"
import { AuthoriModelAddRouteConfig } from "@@/views/authoriModel/AuthoriModelAddRouteConfig"
import { AuthoriModelDetailRouteConfig } from "@@/views/authoriModel/AuthoriModelDetailRouteConfig"
import { AuthoriModelEditRouteConfig } from "@@/views/authoriModel/AuthoriModelEditRouteConfig"
import { AuthoriModelListRouteConfig } from "@@/views/authoriModel/AuthoriModelListRouteConfig"
import { AuthoriModelincludingRuleListRouteConfig } from "@@/views/authoriModelincludingRuleList/AuthoriModelincludingRuleListRouteConfig"
import { CombinatJudgmentDetailRouteConfig } from "@@/views/combinatJudgmentDetail/CombinatJudgmentDetailRouteConfig"
import { CombinatJudgmentAddRouteConfig } from "@@/views/combinatJudgmentEdit/CombinatJudgmentAddRouteConfig"
import { CombinatJudgmentEditRouteConfig } from "@@/views/combinatJudgmentEdit/CombinatJudgmentEditRouteConfig"
import { CompanyAddRouteConfig } from "@@/views/companyAdd/CompanyAddRouteConfig"
import { CompanyDetailRouteConfig } from "@@/views/companyDetail/CompanyDetailRouteConfig"
import { CompanyEditRouteConfig } from "@@/views/companyEdit/CompanyEditRouteConfig"
import { CompanyListRouteConfig } from "@@/views/companyList/CompanyListRouteConfig"
import { EventDetailRouteConfig } from "@@/views/eventDetail/EventDetailRouteConfig"
import { EventListRouteConfig } from "@@/views/eventList/EventListRouteConfig"
import { KeywordAddRouteConfig } from "@@/views/keywordAdd/KeywordAddRouteConfig"
import { KeywordListRouteConfig } from "@@/views/keywordList/KeywordListRouteConfig"
import { LabelAddEditRouteConfig } from "@@/views/labelAddEdit/LabelAddEditRouteConfig"
import { LabelListRouteConfig } from "@@/views/labelList/LabelListRouteConfig"
import { LoginInputRouteConfig } from "@@/views/login/LoginInputRouteConfig"
import { OperatorAddRouteConfig } from "@@/views/operatorAdd/OperatorAddRouteConfig"
import { OperatorDetailRouteConfig } from "@@/views/operatorDetail/OperatorDetailRouteConfig"
import { OperatorEditRouteConfig } from "@@/views/operatorEdit/OperatorEditRouteConfig"
import { OperatorListRouteConfig } from "@@/views/operatorList/OperatorListRouteConfig"
import { OperatorRoleAddRouteConfig } from "@@/views/operatorRoleAdd/OperatorRoleAddRouteConfig"
import { OperatorRoleDetailRouteConfig } from "@@/views/operatorRoleDetail/OperatorRoleDetailRouteConfig"
import { OperatorRoleEditRouteConfig } from "@@/views/operatorRoleEdit/OperatorRoleEditRouteConfig"
import { OperatorRoleListRouteConfig } from "@@/views/operatorRoleList/OperatorRoleListRouteConfig"
import { PasswordChangeRequestRouteConfig } from "@@/views/passwordChange/PasswordChangeRequestRouteConfig"
import { PasswordChangeRouteConfig } from "@@/views/passwordChange/PasswordChangeRouteConfig"
import { ReleaseRulePackageDetailRouteConfig } from "@@/views/releaseRulePackageDetail/ReleaseRulePackageDetailRouteConfig"
import { RuleMasterAddRouteConfig } from "@@/views/ruleMasterAdd/RuleMasterAddRouteConfig"
import { RuleMasterDetailRouteConfig } from "@@/views/ruleMasterDetail/RuleMasterDetailRouteConfig"
import { RuleMasterEditRouteConfig } from "@@/views/ruleMasterEdit/RuleMasterEditRouteConfig"
import { RuleMasterListRouteConfig } from "@@/views/ruleMasterList/RuleMasterListRouteConfig"
import { RuleMasterRelatedEventExtractionPartsEditRouteConfig } from "@@/views/ruleMasterRelatedEventExtractionPartsEdit/RuleMasterRelatedEventExtractionPartsEditRouteConfig"
import { RuleMasterSharingRulePartsEditRouteConfig } from "@@/views/ruleMasterSharingRulePartsEdit/RuleMasterSharingRulePartsEditRouteConfig"
import { RulePackageBulkEditAddConfig } from "@@/views/rulePackageBulkEditAdd/RulePackageBulkEditAddConfig"
import { RulePackageBulkEditDeleteConfig } from "@@/views/rulePackageBulkEditDelete/RulePackageBulkEditDeleteConfig"
import { RulePackagesRouteConfig } from "@@/views/rulePackages/RulePackagesRouteConfig"

import { ReleaseInfoListRouteConfig } from "./releaseInfoList/ReleaseInfoListRouteConfig"

/**
 * ページコンポーネントをvue-routerでロードするための設定ファイルです
 */
export class RouteSetting {
  /**
   * The Array of RouteConfig.
   */
  static pagedefs: Array<RouteConfig> = [
    CombinatJudgmentDetailRouteConfig,
    AdminRoleAddRouteConfig,
    OperatorDetailRouteConfig,
    CombinatJudgmentEditRouteConfig,
    AdminListRouteConfig,
    RuleMasterEditRouteConfig,
    LoginInputRouteConfig,
    { path: "/", redirect: "/login" },
    // 旧ログイン URL でも機能させる設定。ログイン URL 変更通知をしたのち削除する
    { path: "/LoginInput", redirect: "/login" },
    CompanyEditRouteConfig,
    RulePackagesRouteConfig,
    LabelListRouteConfig,
    AdminRoleListRouteConfig,
    AuthoriModelListRouteConfig,
    RuleMasterRelatedEventExtractionPartsEditRouteConfig,
    RuleMasterSharingRulePartsEditRouteConfig,
    ReleaseRulePackageDetailRouteConfig,
    OperatorRoleListRouteConfig,
    OperatorEditRouteConfig,
    AdminRoleDetailRouteConfig,
    KeywordAddRouteConfig,
    ReleaseInfoListRouteConfig,
    AuthoriModelincludingRuleListRouteConfig,
    AuthoriModelDetailRouteConfig,
    RuleMasterAddRouteConfig,
    RulePackageBulkEditAddConfig,
    RulePackageBulkEditDeleteConfig,
    PasswordChangeRequestRouteConfig,
    OperatorRoleAddRouteConfig,
    LabelAddEditRouteConfig,
    AuthoriModelEditRouteConfig,
    AdminRoleEditRouteConfig,
    OperatorAddRouteConfig,
    CompanyListRouteConfig,
    OperatorRoleDetailRouteConfig,
    RuleMasterListRouteConfig,
    EventDetailRouteConfig,
    AdminEditRouteConfig,
    RuleMasterDetailRouteConfig,
    KeywordListRouteConfig,
    OperatorRoleEditRouteConfig,
    CompanyDetailRouteConfig,
    PasswordChangeRouteConfig,
    AdminDetailRouteConfig,
    AdminAddRouteConfig,
    CombinatJudgmentAddRouteConfig,
    EventListRouteConfig,
    AuthoriModelAddRouteConfig,
    OperatorListRouteConfig,
    CompanyAddRouteConfig,
  ]
}
