import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorAddJa extends AbstractLocaleMessageObject {
  readonly title: string = "加盟店オペレータ情報登録"

  readonly operatorName: string = "オペレータ名"

  readonly operatorNamePlaceholder: string = "30文字以内で入力して下さい"

  readonly companyId: string = "加盟店ID"

  readonly roleName: string = "ロール"

  readonly roleNamePlaceholder: string = "選択して下さい"

  readonly email: string = "メールアドレス"

  readonly emailPlaceholder: string = "半角英数字記号のみ使用できます"

  readonly ruleAndAuthoriResultNotifyFlg: string = "ルール発動通知・審査結果通知"

  readonly ruleAndAuthoriResultNotifyStatusTrue: string = "受信する"

  readonly csvRegisterResultMailFlg: string = "ファイルアップロード結果メール通知"

  readonly csvRegisterResultMailStatusTrue: string = "受信する"

  readonly buttonAdd: string = "登録"

  readonly buttonCancel: string = "キャンセル"

  readonly dialogTitle: string = "登録 確認"

  readonly tableName: string = "加盟店オペレータ"

  readonly dialogButtonOk: string = "はい"

  readonly dialogButtonCancel: string = "いいえ"

  readonly noDataRoleList: string = "ロールの登録がありません。"
}
