import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class RuleMasterListJa extends AbstractLocaleMessageObject {
  readonly searchItem: string = "検索項目"

  readonly ruleId: string = "ルールID"

  readonly ruleCode: string = "ルールコード"

  readonly description: string = "ルール説明"

  readonly searchBtn: string = "検索"

  readonly registerBtn: string = "登録"

  readonly deleteBtn: string = "削除"

  readonly ruleVerificationBtn: string = "ルール検証"

  readonly deleatedBtn: string = "削除"

  readonly memo: string = "メモ"

  readonly copyBtn: string = "複写"

  readonly authoriModelBtn: string = "審査モデル"

  readonly version: string = "Ver."

  readonly updatedAt: string = "更新日時"

  readonly okBtn: string = "OK"

  readonly cancelBtn: string = "キャンセル"

  readonly ruleMasterCopyLabel: string = "ルールマスター複写"

  readonly copyRuleCodePH: string = "100文字以内で入力してください。"

  readonly copyRuleDescriptionPH: string = "200文字以内で入力してください。"

  readonly copyRuleMemoPH: string = "200文字以内で入力してください。"

  readonly ruleMasterLabel: string = "ルールマスター"

  readonly ruleVerificationLabel: string = "ルール検証確認"
}
