import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class MenuLabelsEn extends AbstractLocaleMessageObject {
  readonly samples: string = "Sample"

  readonly validate: string = "Validation"

  readonly table: string = "Table Sample"

  readonly adminRoleEdit: string = "Cacco User\nRole Edit"

  readonly companyList: string = "Shop List"

  readonly companyAdd: string = "Shop Register"
}
