import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [CompanyDetail]。
 */
export const CompanyDetailRouteConfig: RouteConfig = {
  path: "/CompanyDetail",
  name: "CompanyDetail",
  component: () => import("@@/views/companyDetail/CompanyDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
