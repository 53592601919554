import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class AdminEditJa extends AbstractLocaleMessageObject {
  readonly title: string = "かっこユーザー情報編集"

  readonly userId: string = "ユーザーID"

  readonly userName: string = "ユーザー名"

  readonly userNamePlaceholder: string = "30文字以内で入力して下さい"

  readonly roleName: string = "ロール"

  readonly email: string = "メールアドレス"

  readonly emailPlaceholder: string = "半角英数字記号のみ使用できます"

  readonly passwordLock: string = "アカウントの状態"

  readonly passwordLockTrue: string = "ロック中"

  readonly passwordLockFalse: string = " - "

  readonly buttonEdit: string = "更新"

  readonly buttonCancel: string = "キャンセル"

  readonly dialogEditTitle: string = "更新 確認"

  readonly tableName: string = "かっこユーザー"
}
