import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [OperatorRoleDetail]。
 */
export const OperatorRoleDetailRouteConfig: RouteConfig = {
  path: "/OperatorRoleDetail",
  name: "OperatorRoleDetail",
  component: () => import("@@/views/operatorRoleDetail/OperatorRoleDetail.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
