import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class CompanyListJa extends AbstractLocaleMessageObject {
  readonly searchtitle: string = "検索項目"

  readonly runButton: string = "検索"

  readonly downloadButton: string = "CSVダウンロード"

  readonly addButton: string = "登録"

  readonly companyId: string = "加盟店ID"

  readonly companyName: string = "加盟店名"

  readonly zipcode: string = "郵便番号"

  readonly address: string = "住所"

  readonly tel: string = "電話番号"

  readonly applicationId: string = "アプリケーションID"

  readonly secretAccessId: string = "シークレットアクセスキー"

  readonly invalid: string = "無効フラグ"

  readonly invalidAt: string = "無効日時"

  readonly updatedAt: string = "更新日時"

  readonly detailButton: string = "詳細"

  readonly noDataLabel: string = "データはありません。"

  readonly searchInvalidCheckbox: string = "無効を含む"
}
