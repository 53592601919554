import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [RuleMasterEdit]。
 */
export const RuleMasterEditRouteConfig: RouteConfig = {
  path: "/rules/:ruleId/edit",
  name: "RuleMasterEdit",
  component: () => import("@@/views/ruleMasterEdit/RuleMasterEdit.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
