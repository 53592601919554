import { RouteConfig } from "vue-router"

import { NoPageRouteConfig } from "@@/views/nopage/NoPageRouteConfig"
import { SystemErrorRouteConfig } from "@@/views/systemError/SystemErrorRouteConfig"

/**
 * ページコンポーネントをvue-routerでロードするための設定ファイルです
 */
export class CommonRouteSetting {
  /**
   * The Array of RouteConfig.
   */
  static pagedefs: Array<RouteConfig> = [NoPageRouteConfig, SystemErrorRouteConfig]
}
