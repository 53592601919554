import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AuthoriModelincludingRuleList]。
 */
export const AuthoriModelincludingRuleListRouteConfig: RouteConfig = {
  path: "/rules/:ruleId/included-rule-packages",
  name: "AuthoriModelincludingRuleList",
  component: () => import("@@/views/authoriModelincludingRuleList/AuthoriModelincludingRuleList.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
