import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class OperatorRoleDetailJa extends AbstractLocaleMessageObject {
  readonly companyId: string = "加盟店ID"

  readonly roleId: string = "加盟店ロールID"

  readonly roleName: string = "加盟店ロール名"

  readonly functionGroup: string = "利用可能機能グループ"

  readonly editAddLabel: string = "編集・登録"

  readonly can: string = "できる"

  readonly canNot: string = "できない"

  readonly notProvideFunction: string = "機能を提供しない"

  readonly editButton: string = "編集"

  readonly backButton: string = "加盟店ロール情報一覧へ戻る"
}
