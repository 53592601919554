import { RouteConfig } from "vue-router"

/**
 * RouteConfigを定義します: [AuthoriModelAdd]。
 */
export const AuthoriModelAddRouteConfig: RouteConfig = {
  path: "/AuthoriModelAdd",
  name: "AuthoriModelAdd",
  component: () => import("@@/views/authoriModel/AuthoriModelAdd.vue"),
  meta: {
    reload: false,
    authRequired: true,
  },
}
